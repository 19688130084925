/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import { resetTrainingFilter } from '../../redux/features/trainingModule/trainingFilterSlice';
import {
    useAddTrainingModuleMutation,
    useGetTrainingModuleDataMutation,
} from '../../redux/features/trainingModule/trainingModuleApi';
import firebaseLog from '../../util/firebaseLog';
import TrainingModuleDataTable from './TrainingModuleDataTable';

function TrainingModule() {
    const dispatch = useDispatch();

    // post data hook
    const [addTrainingModule, { data: trainingModule, isLoading: trainingLoading }] =
        useAddTrainingModuleMutation();

    // get data
    const [getTrainingModuleData, { data, isLoading }] = useGetTrainingModuleDataMutation();

    // loading state
    const [loading, setLoading] = useState(false);

    const { accessToken } = useSelector((state) => state.auth);
    const {
        fileType: type,
        publishDate,
        expireDate,
        fileUrl: url,
        name,
        targetOf,
    } = useSelector((state) => state.trainingFilter);

    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    // generated request body data
    const getInventoryQueryData = () => {
        const bodyData = {};
        if (circle.length) {
            bodyData.circleId = circle.map((item) => item.value);
        }
        if (region.length) {
            bodyData.regionId = region.map((item) => item.value);
        }
        if (territory.length) {
            bodyData.territoryId = territory.map((item) => item.value);
        }
        if (town.length) {
            bodyData.townId = town.map((item) => item.value);
        }
        return bodyData;
    };

    useEffect(() => {
        getTrainingModuleData();
    }, [getTrainingModuleData, trainingModule]);

    const submitModule = async () => {
        if (circle.length || region.length || area.length || territory.length || town.length) {
            if (publishDate && expireDate) {
                if (type) {
                    if (name) {
                        if (url) {
                            if (targetOf.length) {
                                addTrainingModule({
                                    ...getInventoryQueryData(),
                                    fileType: type,
                                    fileName: name,
                                    publishAt: publishDate,
                                    expireAt: expireDate,
                                    targetOf,
                                    fileUrl: `${url}`,
                                });
                            } else {
                                message.error('Please select a target');
                            }
                        } else {
                            message.error('Please upload your file');
                        }
                    } else {
                        message.error('Please provide a name');
                    }
                } else {
                    message.error('Please select file type');
                }
            } else {
                message.error('Please select publish date and expire date');
            }
        } else {
            message.error('You must select the place where you want to give training module.');
        }
    };

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTrainingFilter());
        // window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, trainingModule]);

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Training Module', user: userInfo.name });
    }, [userInfo.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Training Module" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={trainingLoading}
                    queryFunc={submitModule}
                    pathname="/training-module"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Training Module</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <TrainingModuleDataTable loading={isLoading} data={data?.data} />
                </div>
            </div>
        </>
    );
}

export default TrainingModule;
