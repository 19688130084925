/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Image, Modal, Row, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import MTSosCategoryTable from '../../components/MTSosCategoryTable';
import MapModal from '../../components/MapModal';
import OverallMtSosTable from '../../components/OverallMtSosTable';
import { useGetAiResultQuery } from '../../redux/features/report/visitCallApi';
import labelChange from '../../util/labelChange';
import { capitalizeFirstLetter } from '../../util/miniFunction';

const { Column, ColumnGroup } = Table;

function MTCMExecutionItem({ data }) {
    const {
        _id,
        user: { name: ffName, userType: ffLevel, usercode: ffCode },
        town: { name: townName },
        outlet: { name: outletName, outletcode: outletCode, channel, contactNo, route },
        callType,
        passed,
        passedByMs,
        accuracy,
        compliance,
        reasonForNoExecution,
        withinRadius,
        executionStartAt,
        executionEndAt,
        scheduleDate,
        lat,
        lon,
        isAiReady,
        remarks,
        image,
        job,
        reasonForError,
    } = data;
    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [skip, setSkip] = useState(false);
    // execution id
    const [executionId, setExecutionId] = useState('');

    // ai result get api hook
    const { data: aiData, refetch, isLoading } = useGetAiResultQuery({ id: executionId }, { skip });

    const overallSovData = [
        {
            key: '1',
            type: 'Shelf',
            sizeInSize: aiData?.data?.jobsInfo[0]?.overallShelfSize || 0,
            percentage: aiData?.data?.jobsInfo[0]?.overallShelfPercentage || 0,
        },
        {
            key: '2',
            type: 'UBL',
            sizeInSize: aiData?.data?.jobsInfo[0]?.overallUniliverSize || 0,
            percentage: aiData?.data?.jobsInfo[0]?.overallUniliverPercentage || 0,
        },
    ];

    const collapseChange = (e) => {
        if (e.length) {
            setExecutionId(_id);
        }
    };

    useEffect(() => {
        if (executionId) {
            refetch();
        }
    }, [executionId, refetch]);

    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal lat={lat || 0} lon={lon || 0} />
            </Modal>
            <div className="execution-item">
                <Row
                    gap={[5, 5]}
                    justify="space-between"
                    style={!withinRadius ? { background: '#C50B00' } : {}}
                    className={
                        !withinRadius
                            ? 'execution-item-error execution-item-card'
                            : 'execution-item-card'
                    }
                >
                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>FF Name: </span>
                            {ffName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">FF Code: </span>
                            {ffCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">FF Level: </span>
                            {labelChange(ffLevel)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Town: </span>
                            {townName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Code: </span>
                            {outletCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Name: </span>
                            {outletName}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Channel: </span>
                            {channel}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Mobile: </span>
                            {contactNo}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Execution Done: </span>
                            {capitalizeFirstLetter(callType)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Reason for No execution: </span>
                            {reasonForNoExecution}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">AI Run: </span>
                            {isAiReady ? 'Yes' : 'No'}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Remarks: </span>
                            {remarks}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Reason for Error: </span>
                            {reasonForError}
                        </p>

                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Visit Start Timestamp: </span>
                            {dayjs(executionStartAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Visit End Timestamp: </span>
                            {dayjs(executionEndAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                    </Col>

                    <Col
                        lg={6}
                        md={12}
                        sm={24}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <div className="survey-last-section">
                            <Image.PreviewGroup
                                preview={{
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    countRender: (current) =>
                                        (
                                            <span
                                                style={{ background: 'black', padding: '2px 5px' }}
                                            >
                                                {image[current - 1]?.name}
                                            </span>
                                        ) || 'null',
                                }}
                            >
                                <Row gutter={[2, 2]}>
                                    {image?.map(({ original, thumb }, i) => (
                                        <Col key={original + i}>
                                            <Image
                                                width={45}
                                                preview={{ src: original }}
                                                src={thumb || ''}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Image.PreviewGroup>
                        </div>
                        <Button
                            style={{ marginLeft: '5px' }}
                            onClick={() => setIsModalOpen((prev) => !prev)}
                        >
                            <EnvironmentOutlined />
                        </Button>
                    </Col>
                </Row>

                {callType === 'no' || !isAiReady ? null : (
                    <Collapse
                        loading={isLoading}
                        bordered={false}
                        ghost
                        style={{ background: 'transparent' }}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        onChange={collapseChange}
                        items={[
                            {
                                key: '1',
                                label: 'Expand Report',
                                children: (
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <>
                                        {aiData?.data?.jobsInfo?.length ? (
                                            <div className="ai-table-container">
                                                <div>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            fontWeight: 600,
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        Overall MT SOS
                                                    </p>
                                                    <OverallMtSosTable data={overallSovData} />
                                                </div>
                                                {aiData?.data?.jobsInfo[0]?.categories?.map((x) => (
                                                    <div key={x.category}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontWeight: 600,
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            {x.category}
                                                        </p>
                                                        <MTSosCategoryTable data={x} />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </>
                                ),
                                style: {
                                    borderRadius: '5px',
                                    border: 'none',
                                },
                            },
                        ]}
                    />
                )}
            </div>
        </>
    );
}

export default MTCMExecutionItem;
