import { Col, Row, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetAttendanceDataMutation } from '../../redux/features/attendance/attendanceApi';
import { resetAttendanceFilter } from '../../redux/features/attendance/attendanceFilterSlice';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import AttendanceLocator from './AttendanceLocator';
import AttendanceOverview from './AttendanceOverview';
import AttendanceTracker from './AttendanceTracker';

function getBodyData(dateStr, empCode, lev, townCode, locationmatch, lAttendance, empId) {
    const bodyData = {};
    bodyData.date = dayjs(dateStr || new Date()).format('DD-MM-YYYY');
    if (empCode) {
        bodyData.employeeCode = empCode;
    }
    if (lev) {
        bodyData.employeeLevel = lev;
    }
    if (townCode) {
        bodyData.townCode = townCode;
    }
    if (locationmatch) {
        bodyData.isLocationMatched = locationmatch;
    }
    if (lAttendance) {
        bodyData.lateAttendance = lAttendance;
    }
    if (empId) {
        bodyData.employeeId = empId;
    }
    return bodyData;
}

function Attendance() {
    const dispatch = useDispatch();
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { date, employeeCode, level, townCode, locationMatch, lateAttendance, employeeId } =
        useSelector((state) => state.attendanceFilter);
    const { user } = useSelector((state) => state.auth);
    const [getAttendanceData, { data, isLoading }] = useGetAttendanceDataMutation();

    const { reFetchFilter } = useSelector((state) => state.globalLoading);

    const filterData = () => {
        getAttendanceData({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getBodyData(
                date,
                employeeCode,
                level,
                townCode,
                locationMatch,
                lateAttendance,
                employeeId
            ),
        });
    };

    useEffect(() => {
        getAttendanceData();
    }, [getAttendanceData]);

    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetAttendanceFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        dispatch(setGlobalLoading(isLoading));
    }, [dispatch, isLoading]);

    // user information log
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Attendance', user: user.name });
    }, [user.name]);

    // download button
    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    function getDownloadBodyData({ dArr, ffLev }) {
        const bodyData = {
            index: 0,
        };
        if (dArr) {
            bodyData.startDate = dayjs(dArr).startOf('day');
            bodyData.endDate = dayjs(dArr).endOf('day');
        }
        return bodyData;
    }

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    ...getDataManagementFilterData({ region, area, territory, town }),
                    ...getDownloadBodyData({ dArr: date }),
                    ...getBodyData(
                        date,
                        employeeCode,
                        level,
                        townCode,
                        locationMatch,
                        lateAttendance
                    ),
                    // eslint-disable-next-line no-plusplus
                }),
                mode: 'cors',
            });

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Attendance" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    downloadButton={download}
                    loading={isLoading}
                    queryFunc={filterData}
                    pathname="/attendance"
                />
            </div>
            <div className="attendance-overview-container">
                <AttendanceOverview data={data?.data?.meta || []} />
            </div>
            <Row gutter={[5, 10]} style={{ paddingBottom: '10px' }}>
                <Col lg={12}>
                    <AttendanceTracker loading={isLoading} data={data?.data?.list} />
                </Col>
                <Col lg={12}>
                    <AttendanceLocator data={data?.data?.list} />
                </Col>
            </Row>
        </>
    );
}

export default Attendance;
