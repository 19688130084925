/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { AuditOutlined, CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Image, Modal, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import AiResultTable from '../../components/AiResultTable';
import MapModal from '../../components/MapModal';
import { useGetAiResultQuery } from '../../redux/features/report/visitCallApi';
import labelChange from '../../util/labelChange';

const { Column, ColumnGroup } = Table;

function MsCallItem({ data }) {
    const {
        createdAt,
        kind,
        ms: { name: msName, usercode },
        question,
        image: joinCallImage,
        executionStartAt,
        executionEndAt,
        execution: {
            _id,
            user: { name: ffName, userType: ffLevel, usercode: ffCode },
            town: { name: townName },
            outlet: { name: outletName, outletcode: outletCode, channel, contactNo, route },
            callType,
            passed,
            passedByMs,
            accuracy,
            compliance,
            reasonForNoExecution,
            withinRadius,
            scheduleDate,
            lat,
            lon,
            isAiReady,
            remarks,
            image,
            job,
        },
    } = data;

    const imageData = [...image, joinCallImage].filter((x) => x);

    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [skip, setSkip] = useState(false);
    // execution id
    const [executionId, setExecutionId] = useState('');

    // ai result get api hook
    const { data: aiData, refetch, isLoading } = useGetAiResultQuery({ id: executionId }, { skip });

    const collapseChange = (e) => {
        if (e.length) {
            setExecutionId(_id);
        }
    };

    useEffect(() => {
        if (executionId) {
            refetch();
        }
    }, [executionId, refetch]);

    // marking modal
    const [openModal, setOpenModal] = useState(false);
    const markingModalOk = () => {
        setOpenModal(false);
    };
    const markingModalCancel = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal lat={lat || 0} lon={lon || 0} />
            </Modal>

            <Modal
                footer={false}
                title="Rating"
                open={openModal}
                onOk={markingModalOk}
                onCancel={markingModalCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <Table
                    pagination={false}
                    rowKey="question"
                    size="small"
                    scroll={{
                        x: 500,
                    }}
                    dataSource={question || []}
                >
                    <Column title="Rating Parameter" dataIndex="name" key="name" />
                    <Column title="Rating Given" dataIndex="answer" key="answer" align="right" />
                </Table>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <h3>Total</h3>
                    <h3>
                        {question?.reduce(
                            (a, c) => a + (Number.isNaN(Number(c.answer)) ? 0 : Number(c.answer)),
                            0
                        )}
                    </h3>
                </div>
            </Modal>

            <div className="execution-item">
                <Row
                    gap={[5, 5]}
                    justify="space-between"
                    style={!withinRadius ? { background: '#C50B00' } : {}}
                    className={
                        !withinRadius
                            ? 'execution-item-error execution-item-card'
                            : 'execution-item-card'
                    }
                >
                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>Call Type: </span>
                            {kind}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>CM Sup Name: </span>
                            {msName}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>CM Sup Code: </span>
                            {usercode}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>FF Name: </span>
                            {ffName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">FF Code: </span>
                            {ffCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">FF Level: </span>
                            {labelChange(ffLevel)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Town: </span>
                            {townName}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Code: </span>
                            {outletCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Name: </span>
                            {outletName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Channel: </span>
                            {channel}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Mobile: </span>
                            {contactNo}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Route: </span>
                            {route}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Visit Start Timestamp: </span>
                            {dayjs(executionStartAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">POSM AI Accuracy: </span>
                            {job.includes('POSM')
                                ? `${accuracy >= 0 ? `${accuracy?.toFixed(2)}%` : ''}`
                                : ''}
                        </p>
                        {/* <p className="ex-item-point">
                            <span className="ex-item-point-b">
                                Overall Audit Compliance by AI:{' '}
                            </span>
                            {compliance?.toFixed(2) || ''}
                            {compliance >= 0 ? '%' : ''}
                        </p> */}
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Status By AI: </span>
                            {typeof passed === 'boolean' ? (passed ? 'Passed' : 'Failed') : ''}
                        </p>
                        {/* <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Status By CM Sup: </span>
                            {typeof passedByMs === 'boolean'
                                ? passedByMs
                                    ? 'Passed'
                                    : 'Failed'
                                : ''}
                        </p> */}
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Visit End Timestamp: </span>
                            {dayjs(executionEndAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                    </Col>

                    <Col
                        lg={6}
                        md={12}
                        sm={24}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <div className="survey-last-section">
                            <Image.PreviewGroup
                                preview={{
                                    countRender: (current) =>
                                        imageData[current - 1]?.name || 'null',
                                }}
                            >
                                <Row gutter={[2, 2]}>
                                    {imageData?.map(({ original, thumb }, i) => (
                                        <Col key={original + i}>
                                            <Image
                                                width={45}
                                                preview={{ src: original }}
                                                src={thumb || ''}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Image.PreviewGroup>
                        </div>
                        <Space direction="vertical" size="small">
                            <Button
                                style={{ marginLeft: '5px' }}
                                onClick={() => setIsModalOpen((prev) => !prev)}
                            >
                                <EnvironmentOutlined />
                            </Button>
                            <Button
                                style={{ marginLeft: '5px' }}
                                onClick={() => setOpenModal((prev) => !prev)}
                            >
                                <AuditOutlined />
                            </Button>
                        </Space>
                    </Col>
                </Row>

                {callType === 'no' ? null : (
                    <Collapse
                        loading={isLoading}
                        bordered={false}
                        ghost
                        style={{ background: 'transparent' }}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        onChange={collapseChange}
                        items={[
                            {
                                key: '1',
                                label: 'Expand Report',
                                children: (
                                    <Row gutter={[10, 10]}>
                                        {aiData?.data?.jobsInfo?.length ? (
                                            <Col>
                                                <AiResultTable data={aiData?.data?.jobsInfo} />
                                            </Col>
                                        ) : null}
                                    </Row>
                                ),
                                style: {
                                    borderRadius: '5px',
                                    border: 'none',
                                },
                            },
                        ]}
                    />
                )}
            </div>
        </>
    );
}

export default MsCallItem;
