import React from 'react';

import { Table } from 'antd';
import TableSkeleton from '../../ui/TableSkeleton';

// render custom table tag
function renderTableVal(val, subitem, code) {
    return (
        <span style={{ padding: 0, margin: 0 }}>
            {val}
            {subitem ? <span style={{ display: 'block', fontSize: '11px' }}>{code}</span> : null}
        </span>
    );
}

const columns = [
    {
        title: 'Town',
        dataIndex: 'town',
        key: 'town',
        width: 100,
        fixed: 'left',
        render: (v, rec) => renderTableVal(v, true, rec.townCode),
    },
    {
        title: 'Outlet Name',
        dataIndex: 'outletName',
        key: 'outletName',
        width: 200,
        fixed: 'left',
        render: (v, rec) => renderTableVal(v, true, rec.outletCode),
    },
    {
        title: 'Schedule Visit',
        dataIndex: 'scheduledVisit',
        key: 'scheduledVisit',
        width: 80,
        fixed: 'left',
    },
    {
        title: 'Completed Visit',
        dataIndex: 'completedVisit',
        key: 'completedVisit',
        width: 80,
        fixed: 'left',
    },
    {
        title: 'Compliance Status',
        children: [
            {
                title: 'Hair Care',
                dataIndex: 'haircareStatus',
                key: 'haircareStatus',
                width: 100,
            },
            {
                title: 'Face Care',
                dataIndex: 'faceCareStatus',
                key: 'faceCareStatus',
                width: 100,
            },
            {
                title: 'Nutrition',
                dataIndex: 'nutritionStatus',
                key: 'nutritionStatus',
                width: 100,
            },
        ],
    },
    {
        title: 'Hair Care Compliance',
        children: [
            {
                title: 'Visit-1',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'haircareVisit1OC',
                        key: 'haircareVisit1OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'haircareVisit1VC',
                        key: 'haircareVisit1VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'haircareVisit1ShelfTalker',
                        key: 'haircareVisit1ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-2',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'haircareVisit2OC',
                        key: 'haircareVisit2OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'haircareVisit2VC',
                        key: 'haircareVisit2VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'haircareVisit2ShelfTalker',
                        key: 'haircareVisit2ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-3',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'haircareVisit3OC',
                        key: 'haircareVisit3OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'haircareVisit3VC',
                        key: 'haircareVisit3VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'haircareVisit3ShelfTalker',
                        key: 'haircareVisit3ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-4',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'haircareVisit4OC',
                        key: 'haircareVisit4OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'haircareVisit4VC',
                        key: 'haircareVisit4VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'haircareVisit4ShelfTalker',
                        key: 'haircareVisit4ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-5',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'haircareVisit5OC',
                        key: 'haircareVisit5OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'haircareVisit5VC',
                        key: 'haircareVisit5VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'haircareVisit5ShelfTalker',
                        key: 'haircareVisit5ShelfTalker',
                        width: 60,
                    },
                ],
            },
        ],
    },
    {
        title: 'Face Cream Compliance',
        children: [
            {
                title: 'Visit-1',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceCreamVisit1OC',
                        key: 'faceCreamVisit1OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceCreamVisit1VC',
                        key: 'faceCreamVisit1VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceCreamVisit1ShelfTalker',
                        key: 'faceCreamVisit1ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-2',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceCreamVisit2OC',
                        key: 'faceCreamVisit2OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceCreamVisit2VC',
                        key: 'faceCreamVisit2VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceCreamVisit2ShelfTalker',
                        key: 'faceCreamVisit2ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-3',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceCreamVisit3OC',
                        key: 'faceCreamVisit3OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceCreamVisit3VC',
                        key: 'faceCreamVisit3VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceCreamVisit3ShelfTalker',
                        key: 'faceCreamVisit3ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-4',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceCreamVisit4OC',
                        key: 'faceCreamVisit4OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceCreamVisit4VC',
                        key: 'faceCreamVisit4VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceCreamVisit4ShelfTalker',
                        key: 'faceCreamVisit4ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-5',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceCreamVisit5OC',
                        key: 'faceCreamVisit5OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceCreamVisit5VC',
                        key: 'faceCreamVisit5VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceCreamVisit5ShelfTalker',
                        key: 'faceCreamVisit5ShelfTalker',
                        width: 60,
                    },
                ],
            },
        ],
    },
    {
        title: 'Face Wash Compliance',
        children: [
            {
                title: 'Visit-1',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceWashVisit1OC',
                        key: 'faceWashVisit1OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceWashVisit1VC',
                        key: 'faceWashVisit1VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceWashVisit1ShelfTalker',
                        key: 'faceWashVisit1ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-2',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceWashVisit2OC',
                        key: 'faceWashVisit2OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceWashVisit2VC',
                        key: 'faceWashVisit2VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceWashVisit2ShelfTalker',
                        key: 'faceWashVisit2ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-3',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceWashVisit3OC',
                        key: 'faceWashVisit3OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceWashVisit3VC',
                        key: 'faceWashVisit3VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceWashVisit3ShelfTalker',
                        key: 'faceWashVisit3ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-4',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceWashVisit4OC',
                        key: 'faceWashVisit4OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceWashVisit4VC',
                        key: 'faceWashVisit4VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceWashVisit4ShelfTalker',
                        key: 'faceWashVisit4ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-5',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'faceWashVisit5OC',
                        key: 'faceWashVisit5OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'faceWashVisit5VC',
                        key: 'faceWashVisit5VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'faceWashVisit5ShelfTalker',
                        key: 'faceWashVisit5ShelfTalker',
                        width: 60,
                    },
                ],
            },
        ],
    },
    {
        title: 'Nutrition Store Compliance',
        children: [
            {
                title: 'Visit-1',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'nutritionVisit1OC',
                        key: 'nutritionVisit1OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'nutritionVisit1VC',
                        key: 'nutritionVisit1VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'nutritionVisit1ShelfTalker',
                        key: 'nutritionVisit1ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-2',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'nutritionVisit2OC',
                        key: 'nutritionVisit2OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'nutritionVisit2VC',
                        key: 'nutritionVisit2VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'nutritionVisit2ShelfTalker',
                        key: 'nutritionVisit2ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-3',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'nutritionVisit3OC',
                        key: 'nutritionVisit3OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'nutritionVisit3VC',
                        key: 'nutritionVisit3VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'nutritionVisit3ShelfTalker',
                        key: 'nutritionVisit3ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-4',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'nutritionVisit4OC',
                        key: 'nutritionVisit4OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'nutritionVisit4VC',
                        key: 'nutritionVisit4VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'nutritionVisit4ShelfTalker',
                        key: 'nutritionVisit4ShelfTalker',
                        width: 60,
                    },
                ],
            },
            {
                title: 'Visit-5',
                children: [
                    {
                        title: 'O.C',
                        dataIndex: 'nutritionVisit5OC',
                        key: 'nutritionVisit5OC',
                        width: 60,
                    },
                    {
                        title: 'V.C',
                        dataIndex: 'nutritionVisit5VC',
                        key: 'nutritionVisit5VC',
                        width: 60,
                    },
                    {
                        title: 'Shelf Talker',
                        dataIndex: 'nutritionVisit5ShelfTalker',
                        key: 'nutritionVisit5ShelfTalker',
                        width: 60,
                    },
                ],
            },
        ],
    },
];
const obj = {
    0: 'region',
    1: 'area',
    2: 'territory',
    3: 'town',
    4: 'townCode',
    5: 'outletName',
    6: 'outletCode',
    7: 'slab',
    8: 'channel',
    9: 'scheduledVisit',
    10: 'completedVisit',
    11: 'haircareStatus',
    12: 'faceCareStatus',
    13: 'nutritionStatus',
    14: 'haircareVisit1OC',
    15: 'haircareVisit1VC',
    16: 'haircareVisit1ShelfTalker',
    17: 'haircareVisit2OC',
    18: 'haircareVisit2VC',
    19: 'haircareVisit2ShelfTalker',
    20: 'haircareVisit3OC',
    21: 'haircareVisit3VC',
    22: 'haircareVisit3ShelfTalker',
    23: 'haircareVisit4OC',
    24: 'haircareVisit4VC',
    25: 'haircareVisit4ShelfTalker',
    26: 'haircareVisit5OC',
    27: 'haircareVisit5VC',
    28: 'haircareVisit5ShelfTalker',
    29: 'faceCreamVisit1OC',
    30: 'faceCreamVisit1VC',
    31: 'faceCreamVisit1ShelfTalker',
    32: 'faceCreamVisit2OC',
    33: 'faceCreamVisit2VC',
    34: 'faceCreamVisit2ShelfTalker',
    35: 'faceCreamVisit3OC',
    36: 'faceCreamVisit3VC',
    37: 'faceCreamVisit3ShelfTalker',
    38: 'faceCreamVisit4OC',
    39: 'faceCreamVisit4VC',
    40: 'faceCreamVisit4ShelfTalker',
    41: 'faceCreamVisit5OC',
    42: 'faceCreamVisit5VC',
    43: 'faceCreamVisit5ShelfTalker',
    44: 'faceWashVisit1OC',
    45: 'faceWashVisit1VC',
    46: 'faceWashVisit1ShelfTalker',
    47: 'faceWashVisit2OC',
    48: 'faceWashVisit2VC',
    49: 'faceWashVisit2ShelfTalker',
    50: 'faceWashVisit3OC',
    51: 'faceWashVisit3VC',
    52: 'faceWashVisit3ShelfTalker',
    53: 'faceWashVisit4OC',
    54: 'faceWashVisit4VC',
    55: 'faceWashVisit4ShelfTalker',
    56: 'faceWashVisit5OC',
    57: 'faceWashVisit5VC',
    58: 'faceWashVisit5ShelfTalker',
    59: 'nutritionVisit1OC',
    60: 'nutritionVisit1VC',
    61: 'nutritionVisit1ShelfTalker',
    62: 'nutritionVisit2OC',
    63: 'nutritionVisit2VC',
    64: 'nutritionVisit2ShelfTalker',
    65: 'nutritionVisit3OC',
    66: 'nutritionVisit3VC',
    67: 'nutritionVisit3ShelfTalker',
    68: 'nutritionVisit4OC',
    69: 'nutritionVisit4VC',
    70: 'nutritionVisit4ShelfTalker',
    71: 'nutritionVisit5OC',
    72: 'nutritionVisit5VC',
    73: 'nutritionVisit5ShelfTalker',
};

export default function ReportTable({ data, loading }) {
    const finalData = data?.map((x) => x.reduce((acc, c, i) => ({ ...acc, [obj[i]]: c }), {}));
    return (
        <div style={{ borderRadius: '10px' }}>
            <div className="box-heading">Fixed Display Audit</div>

            <div style={{ padding: '10px', width: '100%' }}>
                {loading ? (
                    <TableSkeleton />
                ) : (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={finalData}
                        bordered
                        size="small"
                        scroll={{
                            x: 'calc(1200px + 50%)',
                        }}
                    />
                )}
            </div>
        </div>
    );
}
