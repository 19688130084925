export default function labelChange(label) {
    switch (label) {
        case 'MS':
            return 'CM SUP';
        case 'CC':
            return 'BA';
        case 'MTCM':
            return 'MTM';
        default:
            return label;
    }
}
