import { Button, Col, Input } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setPosmCode, setPosmName } from '../../redux/features/posmManagement/posmViewFilterSlice';

function ViewPosmListFilter({ queryFunc, loading }) {
    const dispatch = useDispatch();

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    placeholder="POSM Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmName(e.target.value))}
                />
            </Col>
            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Type of POSM"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmType(e))}
                    options={[
                        {
                            value: 'Trade Letter',
                            label: 'Trade Letter',
                        },
                        {
                            value: 'Poster',
                            label: 'BPosterkash',
                        },
                        {
                            value: 'Sticker',
                            label: 'Sticker',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Brand"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmBrand(e))}
                    options={[
                        {
                            value: 'Vasline',
                            label: 'Vasline',
                        },
                        {
                            value: 'Dove',
                            label: 'Dove',
                        },
                    ]}
                />
            </Col> */}
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    placeholder="POSM Code"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    disabled={loading}
                    loading={loading}
                    size="large"
                    className="filter-btn"
                    type="primary"
                    onClick={() => queryFunc()}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default ViewPosmListFilter;
