import { Input, Modal, Select, Spin, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetTownListQuery } from '../../redux/features/posmManagement/posmManagementApi';
import {
    useSearchEmployeeMutation,
    useUpdateEmployeeInfoMutation,
} from '../../redux/features/teamManagement/teamManagementApi';

function EditModal({ isModalOpen, setIsModalOpen, userData, fetchSearchData, page, limit }) {
    const [updateEmployeeInfo, { isLoading }] = useUpdateEmployeeInfoMutation();
    // change state
    const [newName, setNewName] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newEmployeeCode, setNewEmployeeCode] = useState('');
    const [supChangeVal, setSupChangeVal] = useState('');
    const [changeMobile, setChangeMobile] = useState('');
    const [updateResignDate, setUpdateResignDate] = useState('');
    const [updateTown, setUpdateTown] = useState([]);
    const [mobile, setMobile] = useState('');

    // search employee api hook
    const [searchEmployee, { data: supData, isLoading: supLoading }] = useSearchEmployeeMutation();

    // get town list
    const { data: townList, isLoading: townLoading } = useGetTownListQuery();

    useEffect(() => {
        searchEmployee({
            // ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            type: 'MS',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };
    const [townSearch, setTownSearch] = useState('');
    const onTownSearch = (e) => {
        setTownSearch(e);
    };

    // get body data
    function getBodyData({ name, username, employeeCode, sup, town, mNumber, resignD }) {
        const bodyData = {};
        if (name) {
            bodyData.name = name;
        }
        if (username) {
            bodyData.username = username;
        }
        if (employeeCode) {
            bodyData.usercode = employeeCode;
        }
        if (sup) {
            bodyData.supervisor = sup;
        }
        if (town.length) {
            bodyData.town = town;
        }
        if (mNumber) {
            bodyData.phone = mNumber;
        }
        if (resignD) {
            bodyData.resignDate = resignD;
        }
        return bodyData;
    }
    const handleOk = async () => {
        try {
            const res = await updateEmployeeInfo({
                id: userData.id,
                data: {
                    ...getBodyData({
                        name: newName,
                        username: newUserName,
                        employeeCode: newEmployeeCode,
                        sup: supChangeVal,
                        town: updateTown,
                        mNumber: mobile,
                        resignD: updateResignDate,
                    }),
                },
            }).unwrap();
            setIsModalOpen(false);
            message.success('Task Completed successfully');
            fetchSearchData(page, limit);
        } catch (error) {
            message.error('Something went wrong!');
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const supChange = (value) => {
        setSupChangeVal(value);
    };

    const townChange = (value) => {
        setUpdateTown(value);
    };

    const resignDateChange = (_, dateString) => {
        setUpdateResignDate(dateString);
    };

    return (
        <Modal
            title="Edit Employee Information"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Update"
            confirmLoading={isLoading}
        >
            {townLoading || supLoading ? (
                <Spin />
            ) : (
                <div>
                    <div>
                        <Typography.Title level={5}>Name</Typography.Title>
                        <Input
                            onChange={(e) => setNewName(e.target.value)}
                            defaultValue={userData.name}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Username</Typography.Title>
                        <Input
                            onChange={(e) => setNewUserName(e.target.value)}
                            defaultValue={userData.username}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Employee Code</Typography.Title>
                        <Input
                            onChange={(e) => setNewEmployeeCode(e.target.value)}
                            defaultValue={userData.usercode}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Phone Number</Typography.Title>
                        <Input
                            onChange={(e) => setMobile(e.target.value)}
                            defaultValue={userData.phone}
                        />
                    </div>

                    {/* <div>
                        <Typography.Title level={5}>Resign Date</Typography.Title>
                        <DatePicker onChange={resignDateChange} />
                    </div> */}

                    <div>
                        <Typography.Title level={5}>Town</Typography.Title>
                        <Select
                            loading={townLoading}
                            mode="multiple"
                            allowClear
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select"
                            defaultValue={userData?.town
                                ?.split(', ')
                                ?.map((x) => townList?.data?.find((y) => y.label === x).value)}
                            onChange={townChange}
                            options={townList?.data}
                            filterOption={(input, option) =>
                                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            searchValue={townSearch}
                            onSearch={onTownSearch}
                        />
                    </div>

                    {userData?.kind === 'CM' ? (
                        <div>
                            <Typography.Title level={5}>Supervisor</Typography.Title>
                            <Select
                                loading={supLoading}
                                allowClear
                                showSearch
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                defaultValue={[userData.supervisorName]}
                                onChange={supChange}
                                options={supData?.data?.map((doc) => ({
                                    label: doc.name,
                                    // eslint-disable-next-line no-underscore-dangle
                                    value: doc._id,
                                }))}
                                filterOption={(input, option) =>
                                    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                searchValue={search}
                                onSearch={onSearch}
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </Modal>
    );
}

export default EditModal;
