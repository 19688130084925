/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';

function DraftDownload() {
    const dispatch = useDispatch();

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Draft PJP Download', user: user.name });
    }, [user.name]);

    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    const download = async () => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(
                `${process.env.REACT_APP_API_URL}${'/v1/pjp-management/download-draft-file'}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: ` Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        // eslint-disable-next-line no-plusplus
                    }),
                    mode: 'cors',
                }
            );

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            <HelmetHeader title="Draft PJP Download" />
            <div style={{ margin: '10px' }}>
                <div className="box-heading">Draft PJP Download</div>
                <div
                    style={{
                        boxShadow: '0 0 5px 0 #cec6c6',
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    <Space style={{ marginTop: '10px' }}>
                        <p
                            style={{
                                margin: 0,
                                color: '#1d1c1c',
                                fontSize: '18px',
                                fontWeight: 500,
                            }}
                        >
                            To download draft PJP list click to download button
                        </p>
                        <Button
                            disabled={loading}
                            loading={loading}
                            onClick={download}
                            size="large"
                        >
                            Download
                        </Button>
                    </Space>
                </div>
            </div>
        </>
    );
}

export default DraftDownload;
