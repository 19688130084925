/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import excelIcon from '../../assets/excel.png';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import { usePjpDraftUploadMutation } from '../../redux/features/pjpManagement/pjpManagementApi';
import firebaseLog from '../../util/firebaseLog';

function PjpDraftUpload() {
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (successText, description) => {
        api.open({
            message: successText,
            duration: 0,
        });
    };

    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const [pjpDraftUpload, { data, isLoading }] = usePjpDraftUploadMutation();

    const submitMaterial = async () => {
        if (selectedFile?.name) {
            try {
                dispatch(setGlobalLoading(false));

                const formData = new FormData();
                formData.append('file', selectedFile);

                const result = await pjpDraftUpload(formData).unwrap();
                setSelectedFile(null);
                if (result?.message) {
                    message.success(result.message);
                    openNotification(result.message);
                }
            } catch (error) {
                message.error(error?.data?.message);
            } finally {
                dispatch(setGlobalLoading(false));
            }
        } else {
            message.error('Please select a file');
        }
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'PJP Allocation', user: user.name });
    }, [user.name]);

    return (
        <>
            {contextHolder}
            <HelmetHeader title="PJP Draft Upload" />
            <div style={{ margin: '10px' }}>
                <div
                    style={{
                        boxShadow: '0 0 5px 0 #cec6c6',
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    <div className="file-upload-container">
                        <p style={{ margin: '0 5px', fontSize: '14px' }}>Upload File</p>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <img src={excelIcon} alt="Upload Icon" className="upload-icon" />
                            Choose File
                        </label>
                        <input
                            type="file"
                            id="file-upload"
                            onChange={handleFileChange}
                            accept=".xlsx"
                        />
                        {selectedFile && (
                            <div className="selected-file-name">{selectedFile.name}</div>
                        )}
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <Button
                            disabled={isLoading}
                            loading={isLoading}
                            onClick={submitMaterial}
                            size="large"
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PjpDraftUpload;
