import { Col, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../../components/CommonButton';
import {
    setDate,
    setEmpCode,
    setEmpId,
    setEmpLevel,
    setIsPjp,
} from '../../redux/features/routePlanTracker/routePlanTrackerFilterSlice';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import labelChange from '../../util/labelChange';

function RoutePlanTrackerFilter({ queryFunc, loading }) {
    const { date, empId, empLevel, empCode } = useSelector((state) => state.routePlanTrackerFilter);

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const dispatch = useDispatch();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    // date picker function
    const onChange = (_, dateStr) => {
        dispatch(setDate(dateStr));
    };

    const getFilterData = (lev) => {
        const bodyData = {};
        if (lev) {
            bodyData.type = lev;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(empLevel),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, town, empLevel]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    const projectAccessData = user?.projectAccess?.map((x) => ({
        label: labelChange(x),
        value: x,
    }));

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    // format="DD-MM-YYYY"
                    value={date ? dayjs(date) : ''}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Date"
                    onChange={onChange}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="EMP Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEmpLevel(e))}
                    options={projectAccessData || []}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empCode}
                    placeholder="Emp Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="EMP Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEmpId(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setIsPjp(e))}
                    options={[
                        {
                            value: 'PJP',
                            label: 'PJP',
                        },
                        {
                            value: 'NON PJP',
                            label: 'NON PJP',
                        },
                        {
                            value: 'BOTH',
                            label: 'BOTH',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <CommonButton loading={loading} queryFunc={queryFunc} />
            </Col>
        </>
    );
}

export default RoutePlanTrackerFilter;
