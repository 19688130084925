import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetTownListQuery } from '../../redux/features/posmManagement/posmManagementApi';
import {
    useAddEmployeeMutation,
    useSearchEmployeeMutation,
} from '../../redux/features/teamManagement/teamManagementApi';
import firebaseLog from '../../util/firebaseLog';

function AddEmployee() {
    const { Option } = Select;
    const [form] = Form.useForm();

    // employee add api hook
    const [addEmployee, { data: addData, isLoading: addLoading }] = useAddEmployeeMutation();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    const [isVisibleSupervisor, setIsVisibleSupervisor] = useState(false);

    // get town list
    const { data, isLoading: townLoading } = useGetTownListQuery();

    const onFinish = async (values) => {
        try {
            const result = await addEmployee({ ...values }).unwrap();
            message.success(result?.message);
            form.resetFields();
        } catch (error) {
            message.error(JSON.stringify(error?.data?.message));
        }
    };

    useEffect(() => {
        if (isVisibleSupervisor) {
            searchEmployee({ type: 'MS' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisibleSupervisor]);

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Add Employee', user: userInfo.name });
    }, [userInfo.name]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };
    const [search2, setSearch2] = useState('');
    const onSearch2 = (e) => {
        setSearch2(e);
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Add Employee" />

            <div className="add-user-form-container">
                <Form autoComplete="none" form={form} className="add-user-form" onFinish={onFinish}>
                    <h2>Employee Registration Form</h2>

                    <Row gutter={[30, 10]}>
                        <Col lg={12}>
                            <Form.Item
                                label="FF Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="FF Code"
                                name="usercode"
                                rules={[{ required: true, message: 'Please enter your FF code' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="FF Level"
                                name="kind"
                                rules={[{ required: true, message: 'Please select a level' }]}
                            >
                                <Select
                                    onChange={(e) => {
                                        if (e === 'CM') {
                                            setIsVisibleSupervisor(true);
                                        } else {
                                            setIsVisibleSupervisor(false);
                                        }
                                    }}
                                    placeholder="Emp Level"
                                >
                                    <Option value="CM">CM</Option>
                                    <Option value="MS">CM SUP</Option>
                                    <Option value="WMA">WMA</Option>
                                    <Option value="MTCM">MTM</Option>
                                    <Option value="DFF">DFF</Option>
                                    <Option value="CC">BA</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="FF Phone" name="phone">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col lg={12}>
                            <Form.Item
                                label="FF Username"
                                name="username"
                                rules={[{ required: true, message: 'Please enter your username' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="FF Password"
                                name="password"
                                rules={[
                                    { required: true, message: 'Please enter your password' },
                                    {
                                        min: 6,
                                        message: 'Password must be at least 6 characters long',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            {isVisibleSupervisor ? (
                                <Form.Item
                                    label="FF Supervisor"
                                    name="supervisor"
                                    rules={[
                                        { required: true, message: 'Please select a supervisor' },
                                    ]}
                                >
                                    <Select
                                        loading={isLoading}
                                        showSearch
                                        placeholder="Supervisor Name"
                                        options={
                                            employeeData?.data?.map((user) => ({
                                                label: user.name,
                                                // eslint-disable-next-line no-underscore-dangle
                                                value: user._id,
                                            })) || []
                                        }
                                        filterOption={(input, option) =>
                                            option.props.label
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        searchValue={search2}
                                        onSearch={onSearch2}
                                    />
                                </Form.Item>
                            ) : null}

                            <Form.Item
                                label="FF Town"
                                name="town"
                                rules={[{ required: true, message: 'Please select FF DH' }]}
                            >
                                <Select
                                    autoComplete="none"
                                    mode="multiple"
                                    placeholder="Town Name"
                                    options={data?.data}
                                    filterOption={(input, option) =>
                                        option.props.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    searchValue={search}
                                    onSearch={onSearch}
                                />
                            </Form.Item>

                            <Form.Item label="FF Email" name="email">
                                <Input type="email" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button
                            disabled={addLoading}
                            loading={addLoading}
                            style={{ width: '100%' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Add Employee
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}

export default AddEmployee;
