/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, InputNumber, Select, Space, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import {
    useCreateMaterialTransferRequestMutation,
    useGetSingleTownPosmQuery,
    useGetTownAllInfoMutation,
    useGetTownListQuery,
} from '../../redux/features/posmManagement/posmManagementApi';
import firebaseLog from '../../util/firebaseLog';

const { Column, ColumnGroup } = Table;
const { Option } = Select;

function TownPosmMovement() {
    const dispatch = useDispatch();

    // town information
    const [formTownInfo, setFormTownInfo] = useState({});
    const [toTownInfo, setToTownInfo] = useState({});

    // get town list
    const { data, isLoading } = useGetTownListQuery();

    const [formTown, setFormTown] = useState('');
    const [toTown, setToTown] = useState('');

    const [getTownAllInfo, { data: allTownData }] = useGetTownAllInfoMutation();

    useEffect(() => {
        async function getTownInfo() {
            try {
                getTownAllInfo().unwrap();
            } catch (error) {
                message.error('Please clear "To Town Box Data" and select again');
            }
        }
        if (formTown && toTown) {
            getTownInfo();
        }
    }, [formTown, toTown, getTownAllInfo]);

    const [skip, setSkip] = useState(false);

    // get single town material information
    const {
        data: singleData,
        refetch,
        isLoading: stockLoading,
    } = useGetSingleTownPosmQuery({ id: formTown }, { skip });

    // input data
    const [sendItem, setSendItem] = useState([]);

    // input data collect function
    const changeFunc = (record, value) => {
        setSendItem((prev) => {
            if (prev.find((item) => item.id === record.id)) {
                return [
                    ...prev.filter((x) => x.id !== record.id),
                    { id: record.id, quantity: value, ...record },
                ].filter((x) => x.quantity > 0);
            }
            return [...prev, { id: record.id, quantity: value, ...record }].filter(
                (x) => x.quantity > 0
            );
        });
    };

    // transfer request api hook
    const [createMaterialTransferRequest, { isLoading: transferLoading }] =
        useCreateMaterialTransferRequestMutation();

    // transfer data
    const sendData = () => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const sendDataNormalize = {
                            from: {
                                // eslint-disable-next-line no-underscore-dangle
                                ...allTownData?.data?.find((x) => x._id === formTown),
                                id: formTown,
                            },
                            to: {
                                // eslint-disable-next-line no-underscore-dangle
                                ...allTownData?.data?.find((x) => x._id === toTown),
                                id: toTown,
                            },
                            material: sendItem,
                        };
                        try {
                            const result = await createMaterialTransferRequest({
                                ...sendDataNormalize,
                            }).unwrap();
                            message.success('Request send', 10);
                            setFormTown('');
                            setToTown('');
                            setSendItem([]);
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    // data receive and set global loading
    useEffect(() => {
        const loading = stockLoading || transferLoading;
        dispatch(setGlobalLoading(loading));
    }, [dispatch, stockLoading, transferLoading]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Material Movement', user: user.name });
    }, [user.name]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    const [searchTo, setSearchTo] = useState('');
    const onSearchTo = (e) => {
        setSearchTo(e);
    };

    // get stock data
    useEffect(() => {
        if (formTown) {
            setSkip(false);
            refetch();
        }
    }, [refetch, formTown]);

    return (
        <div style={{ margin: '15px' }}>
            <HelmetHeader title="Material Movement" />

            <div
                style={{
                    padding: '10px',
                    marginTop: '8px',
                    boxShadow: '0 0 5px 0 #d6d0d0',
                    borderRadius: '10px',
                }}
            >
                <p style={{ fontSize: '20px', fontWeight: 500 }}>Town POSM Movement</p>

                <div style={{ marginBottom: '10px' }}>
                    <Space>
                        <div>
                            <label htmlFor="from-town" className="required-label">
                                From Town
                            </label>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <Select
                                    allowClear
                                    showSearch
                                    size="large"
                                    id="from-town"
                                    placeholder="Select From Town"
                                    style={{ minWidth: '220px' }}
                                    options={data?.data}
                                    value={formTown || null}
                                    onChange={(e) => {
                                        setFormTown(e);
                                        setToTown('');
                                        refetch();
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    searchValue={search}
                                    onSearch={onSearch}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="to-town" className="required-label">
                                TO Town
                            </label>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    searchValue={searchTo}
                                    onSearch={onSearchTo}
                                    size="large"
                                    id="to-town"
                                    placeholder="Select to Town"
                                    style={{ minWidth: '220px' }}
                                    value={toTown || null}
                                    options={
                                        formTown
                                            ? data?.data?.filter(({ value }) => value !== formTown)
                                            : []
                                    }
                                    onChange={(e) => setToTown(e)}
                                    // Add any other props you need for Select Field 2
                                />
                            </div>
                        </div>
                    </Space>
                </div>

                <Table
                    rowKey="id"
                    scroll={{ x: 800 }}
                    pagination={false}
                    size="small"
                    dataSource={
                        (formTown && singleData?.data?.material?.filter((x) => x.remaining > 0)) ||
                        []
                    }
                >
                    {/* <Column title="S.No" dataIndex="category" key="category" /> */}
                    <Column title="POSM Name" dataIndex="name" key="categonamery" />
                    <Column title="POSM Type" dataIndex="category" key="category" />
                    <Column title="Brand Name" dataIndex="company" key="company" />
                    <Column title="In Hand Qty" dataIndex="remaining" key="remaining" />
                    <Column
                        title="Transfer Qty"
                        key="receive"
                        align="right"
                        render={(_, record) => (
                            <Space>
                                {record.remaining > 0 ? (
                                    <InputNumber
                                        onChange={(e) => changeFunc(record, e)}
                                        size="large"
                                        min={0}
                                        max={record.remaining}
                                        placeholder="Qty"
                                    />
                                ) : null}
                            </Space>
                        )}
                    />
                </Table>

                <div
                    style={{
                        margin: '0 auto',
                        padding: '10px 0 5px 0',
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    {sendItem.length && formTown && toTown ? (
                        <Button
                            disabled={transferLoading}
                            loading={transferLoading}
                            onClick={sendData}
                            className="common-bg"
                            size="large"
                        >
                            Transfer
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default TownPosmMovement;
