import { apiSlice } from '../api/apiSlice';

export const superUserApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPermissionTableData: builder.query({
            query: () => ({
                url: '/v1/auth/permission/list',
            }),
        }),
        createNewGroup: builder.mutation({
            query: (data) => ({
                url: '/v1/auth/role-has-permission',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['groupList'],
        }),
        viewGroupList: builder.query({
            query: () => ({
                url: `/v1/auth/role-has-permission?page=1&limit=100`,
            }),
            providesTags: ['groupList'],
        }),
        viewGroupListValues: builder.query({
            query: () => ({
                url: '/v1/acl/role-has-permission/list',
            }),
        }),
        viewGroupIds: builder.query({
            query: () => ({
                url: '/v1/acl/role-has-permission/list',
            }),
        }),
        landingPageList: builder.query({
            query: () => ({
                url: '/v1/acl/permission/list',
            }),
        }),
        createAdminUser: builder.mutation({
            query: (data) => ({
                url: '/v1/user/admin/register',
                method: 'POST',
                body: data,
            }),
        }),
        getAdminUserList: builder.mutation({
            query: (data) => ({
                url: '/v1/user/admin',
                method: 'POST',
                body: data,
            }),
        }),
        updatePortalUser: builder.mutation({
            query: ({ data, id }) => ({
                url: `/v1/user/admin/${id}`,
                method: 'PATCH',
                body: data,
            }),
        }),
        permissionList: builder.query({
            query: () => ({
                url: '/v1/auth/permission/list',
            }),
        }),
        groupUpdate: builder.mutation({
            query: ({ data, id }) => ({
                url: `/v1/auth/role-has-permission/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['groupList'],
        }),
    }),
});

export const {
    useGetPermissionTableDataQuery,
    useCreateNewGroupMutation,
    useViewGroupListQuery,
    useViewGroupIdsQuery,
    useLandingPageListQuery,
    useCreateAdminUserMutation,
    useGetAdminUserListMutation,
    useViewGroupListValuesQuery,
    useUpdatePortalUserMutation,
    usePermissionListQuery,
    useGroupUpdateMutation,
} = superUserApi;
