import { InfoOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import React from 'react';
import ProgressCircle from '../../../components/ProgressCircle';

function OutletWiseCompliance({ outletWiseCompliance, nationalOutletWiseCompliance }) {
    // tooltip content
    const text = (
        <div style={{ textAlign: 'center' }}>
            <span
                className="tooltip-custom-text"
                style={{
                    textDecoration: 'underline',
                    fontSize: '18px',
                }}
            >
                National
            </span>
            {nationalOutletWiseCompliance
                ?.filter((x) => x.name !== 'DS' && x.name !== 'QPDS')
                ?.map((x) => (
                    <React.Fragment key={x.name}>
                        {x.name === 'QPDS' ? (
                            <span className="tooltip-custom-text">
                                {x.name} :{x.quantity}%
                            </span>
                        ) : (
                            <span className="tooltip-custom-text">
                                {x.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{x.quantity}%
                            </span>
                        )}
                    </React.Fragment>
                ))}
        </div>
    );

    const styles = {
        DS: {
            label: 'Drug Store',
            colors: {
                '0%': '#F52F71',
                '100%': '#FBA32D',
            },
        },
        NS: {
            label: 'Nutrition Store',
            colors: {
                '0%': '#5758BD',
                '100%': '#4F4AA8',
            },
        },
        BS: {
            label: 'Beauty Store',
            colors: {
                '0%': '#25AADA',
                '100%': '#1694DA',
            },
        },
        'Pepsodent QPDS': {
            label: 'Pepsodent QPDS',
            colors: {
                '0%': '#894FAF',
                '100%': '#087FF5',
            },
        },
        'Laabher Bazar QPDS': {
            label: 'Laabher Bazar QPDS',
            colors: {
                '0%': '#F52F71',
                '100%': '#FBA32D',
            },
        },
    };

    // chart data
    const data = outletWiseCompliance
        ?.map((x) => ({
            id: crypto.randomUUID(),
            ...styles[x.name],
            data: x.quantity,
        }))
        ?.filter((x) => x.label !== 'Drug Store');

    function calculateWidth() {
        const windowWidth = window.innerWidth;
        return windowWidth <= 650 ? '260px' : '260px';
    }

    return (
        <div className="box-container" style={{ height: calculateWidth() }}>
            <div className="box-heading box-heading-dash">Program Wise Compliance</div>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Space
                    wrap
                    align="center"
                    style={{
                        marginTop: '35px',
                        justifyContent: calculateWidth() === '380px' ? 'center' : '',
                    }}
                >
                    {data.map((doc) => (
                        <ProgressCircle
                            // eslint-disable-next-line react/no-array-index-key
                            key={doc.id}
                            label={doc.label}
                            data={doc.data}
                            size={140}
                            colors={doc.colors}
                        />
                    ))}
                </Space>
            </div>
            <div className="chart-button-container" style={{ top: '-5px' }}>
                <Tooltip placement="bottomRight" title={text}>
                    <Button size="small" type="primary" shape="circle" icon={<InfoOutlined />} />
                </Tooltip>
            </div>
            <div style={{ textAlign: 'right', padding: '15px 10px 10px 10px' }}>
                <p style={{ margin: 0, fontSize: '15px' }}>Based On</p>
                <p style={{ fontSize: '15px' }}>Planned VS AI Detected</p>
            </div>
        </div>
    );
}

export default OutletWiseCompliance;
