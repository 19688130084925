import { Col, Row, Space } from 'antd';
import React from 'react';

function renameUser(name) {
    if (name === 'MS') {
        return 'CM SUP';
    }
    if (name === 'MTCM') {
        return 'MTM';
    }
    if (name === 'CC') {
        return 'BA';
    }
    return name;
}

function AttendanceCategory({ userType, name, total, present, onLeave, error, lateError }) {
    return (
        <Space direction="horizontal" key={userType}>
            <p className="attendance-info-txt">{renameUser(userType)}:</p>
            {name === 'MS' && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
            <p className="attendance-info-txt">{total}</p>
            <div style={{ width: '1px', height: '10px', background: 'gray' }} />
            <p className="attendance-info-txt">Present: {present}</p>
            <div style={{ width: '1px', height: '10px', background: 'gray' }} />
            <p className="attendance-info-txt">Absent: {total - (present + onLeave)}</p>
            <div style={{ width: '1px', height: '10px', background: 'gray' }} />
            {userType === 'CM' || userType === 'MS' ? (
                <>
                    <p className="attendance-info-txt">Leave: {onLeave}</p>
                    <div style={{ width: '1px', height: '10px', background: 'gray' }} />
                </>
            ) : null}

            <p className="attendance-info-txt">LOC. Err. {error}</p>
            <div style={{ width: '1px', height: '10px', background: 'gray' }} />
            <p className="attendance-info-txt">Late ATT. {lateError}</p>
        </Space>
    );
}

function AttendanceOverview({ data }) {
    const renderAttendanceCategory = (userTypes) =>
        // eslint-disable-next-line react/jsx-props-no-spreading
        userTypes.map((y) => <AttendanceCategory key={y.userType} {...y} />);

    const cmWmaData = data?.filter((x) => x.userType === 'CM' || x.userType === 'WMA');
    const msMtcmData = data?.filter((x) => x.userType === 'MS' || x.userType === 'MTCM');
    const baData = data?.filter((x) => x.userType === 'CC');

    return (
        <Row justify="space-between">
            <Col>
                <Space direction="vertical">{renderAttendanceCategory(cmWmaData)}</Space>
            </Col>
            <Col>
                <Space direction="vertical">{renderAttendanceCategory(msMtcmData)}</Space>
            </Col>
            <Col>
                <Space style={{ marginTop: '10px' }} direction="vertical">
                    {renderAttendanceCategory(baData)}
                </Space>
            </Col>
        </Row>
    );
}

export default AttendanceOverview;
