import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

function CommonButton({ loading, queryFunc }) {
    return (
        <Button
            loading={loading}
            disabled={loading}
            size="large"
            className="filter-btn"
            icon={<SearchOutlined />}
            type="primary"
            onClick={queryFunc}
        >
            Search
        </Button>
    );
}

export default CommonButton;
