import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { useAddPosmMutation } from '../../redux/features/posmManagement/posmManagementApi';

function AddPosm() {
    const { accessToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileUrl, setFileUrl] = useState('');

    const fileChange = async (e) => {
        setLoading(true);
        try {
            const fileData = e.target.files[0];
            const formData = new FormData();
            formData.append('file', fileData);

            setFileName(fileData.name);

            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/v1/upload/file/${dayjs(new Date()).format(
                    'YYYY-MM-DD'
                )}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: formData,
                }
            );
            const result = await res.json();
            setFileUrl(result.data.fileUrl || result.data.original);
            message.success(result.message);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const { Option } = Select;

    const [form] = Form.useForm();

    // add tickets api hook
    const [addPosm, { data, isLoading }] = useAddPosmMutation();

    const onFinish = async (values) => {
        try {
            const bodyData = {
                ...values,
                channel: values.channel.join(', '),
                image: {
                    name: fileName,
                    original: fileUrl,
                    thumb: fileUrl,
                },
            };
            const result = await addPosm({ ...bodyData }).unwrap();
            message.success('POSM Added Successfully');
            form.resetFields();
            setFileUrl('');
        } catch (error) {
            message.error('Something went wrong');
        }
    };
    return (
        <>
            <HelmetHeader title="Add POSM" />
            <div
                style={{
                    borderRadius: '10px',
                    marginTop: '20px',
                    boxShadow: '0 0 2px 0px #e5e2e2',
                    background: '#fff',
                }}
            >
                <div className="box-heading">Add POSM</div>
                <Form
                    style={{ marginTop: '10px', padding: '10px' }}
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[10, 10]}>
                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="POSM Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter the POSM Name' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Type"
                                name="kind"
                                rules={[{ required: true, message: 'Please select the POSM type' }]}
                            >
                                <Select>
                                    <Option value="Execution">Execution</Option>
                                    <Option value="Detection">Detection</Option>
                                    <Option value="Both">Both</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    { required: true, message: 'Please select the POSM Category' },
                                ]}
                            >
                                <Select>
                                    <Option value="Shelf In Shelf">Shelf In Shelf</Option>
                                    <Option value="Wobbler">Wobbler</Option>
                                    <Option value="Brochure">Brochure</Option>
                                    <Option value="Poster">Poster</Option>
                                    <Option value="Hanger">Hanger</Option>
                                    <Option value="Shelf Talker">Shelf Talker</Option>
                                    <Option value="Sticker">Sticker</Option>
                                    <Option value="Bunting">Bunting</Option>
                                    <Option value="Trade Letter">Trade Letter</Option>
                                    <Option value="Shelf Marker">Shelf Marker</Option>
                                    <Option value="Dengler">Dengler</Option>
                                    <Option value="Shop Identifier">Shop Identifier</Option>
                                    <Option value="Sunshade Banner">Sunshade Banner</Option>
                                    <Option value="Shutter Sticker">Shutter Sticker</Option>
                                    <Option value="Table Poster">Table Poster</Option>
                                    <Option value="Shelf Box">Shelf Box</Option>
                                    <Option value="Price Board">Price Board</Option>
                                    <Option value="PLACARD">PLACARD</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Brand"
                                name="company"
                                rules={[{ required: true, message: 'Please enter the Brand Name' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Channel"
                                name="channel"
                                rules={[
                                    { required: true, message: 'Please select the POSM channel' },
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder="Select Channel"
                                    style={{ width: '100%' }}
                                    mode="multiple"
                                >
                                    <Option value="RNG">RNG</Option>
                                    <Option value="RWMG">RWMG</Option>
                                    <Option value="UNG">UNG</Option>
                                    <Option value="UWMG">UWMG</Option>
                                    <Option value="UGS">UGS</Option>
                                    <Option value="PGS">PGS</Option>
                                    <Option value="WS">WS</Option>
                                    <Option value="RCS">RCS</Option>
                                    <Option value="FOOD">FOOD</Option>
                                    <Option value="DRUG">DRUG</Option>
                                    <Option value="TONG">TONG</Option>
                                    <Option value="HOTEL">HOTEL</Option>
                                    <Option value="UCS">UCS</Option>
                                    <Option value="UCS+">UCS+</Option>
                                    <Option value="UHPCT">UHPCT</Option>
                                    <Option value="DS">DS</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="POSM Code"
                                name="materialCode"
                                rules={[{ required: true, message: 'Please enter the POSM Code' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col sm={12} md={8} lg={6}>
                            <Form.Item
                                label="POSM Image"
                                name="posmImage"
                                rules={[{ required: true, message: 'Please Upload POSM Image' }]}
                            >
                                <div>
                                    {fileUrl ? (
                                        <p style={{ color: '#000' }}>{fileName}</p>
                                    ) : (
                                        <input
                                            className="training-file"
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            onChange={fileChange}
                                        />
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button
                            disabled={isLoading}
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            ADD POSM
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}

export default AddPosm;
