/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Col, Row, Select, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import excelIcon from '../../assets/excel.png';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetPosmListQuery } from '../../redux/features/inventory/inventoryApiSlice';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import { useAllocateMaterialViaExcelMutation } from '../../redux/features/materialManagement/materialManagementApi';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function PosmAllocation() {
    const { data: posmList, isLoading: posmListLoading } = useGetPosmListQuery();

    const [selectedPosm, setSelectedPosm] = useState([]);

    // data management filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const handleChange = (value) => {
        setSelectedPosm(value);
    };

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (successText, description) => {
        api.open({
            message: successText,
            duration: 0,
        });
    };

    const [selectedFile, setSelectedFile] = useState(null);

    const dispatch = useDispatch();

    // download button
    async function downLoadingFile(doc) {
        const excName =
            doc?.headers.get('Content-Disposition').split('"')[1] ||
            'POSM allocation template.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    function getDownloadBodyData({ posmIds }) {
        const bodyData = {
            index: 0,
        };
        if (posmIds.length) {
            bodyData.materialId = posmIds;
        }
        return bodyData;
    }

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    ...getDataManagementFilterData({ region, area, territory, town }),
                    ...getDownloadBodyData({ posmIds: selectedPosm }),
                    // eslint-disable-next-line no-plusplus
                }),
                mode: 'cors',
            });

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    const [allocateMaterialViaExcel, { data, isLoading }] = useAllocateMaterialViaExcelMutation();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const submitMaterial = async () => {
        if (selectedFile?.name) {
            try {
                dispatch(setGlobalLoading(false));

                const formData = new FormData();
                formData.append('file', selectedFile);

                const result = await allocateMaterialViaExcel(formData).unwrap();
                setSelectedFile(null);
                if (result?.message) {
                    message.success(result.message);
                    openNotification(result.message);
                }
            } catch (error) {
                message.error(error?.data?.message);
            } finally {
                dispatch(setGlobalLoading(false));
            }
        } else {
            message.error('Please select a file');
        }
    };

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'POSM Allocation', user: user.name });
    }, [user.name]);
    return (
        <>
            {contextHolder}
            <HelmetHeader title="POSM Allocation" />

            <div style={{ margin: '10px' }}>
                <div className="box-heading">POSM Allocate via Excel</div>
                <div
                    style={{
                        boxShadow: '0 0 5px 0 #cec6c6',
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    <Filter />
                    <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
                        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                            <Select
                                showSearch
                                size="large"
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                onChange={handleChange}
                                options={posmList?.data?.data?.map((x) => ({
                                    label: x.name,
                                    // eslint-disable-next-line no-underscore-dangle
                                    value: x._id,
                                }))}
                                filterOption={(input, option) =>
                                    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                searchValue={search}
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                            <Button
                                size="large"
                                type="primary"
                                onClick={() =>
                                    download({
                                        url: '/v1/material/town-material-allocation-template',
                                        fileName: 'town-material-allocation-template',
                                    })
                                }
                            >
                                Download
                            </Button>
                        </Col>
                    </Row>

                    <div className="file-upload-container" style={{ marginTop: '10px' }}>
                        <p style={{ margin: '0 5px', fontSize: '14px' }}>Upload File</p>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <img src={excelIcon} alt="Upload Icon" className="upload-icon" />
                            Choose File
                        </label>
                        <input
                            type="file"
                            id="file-upload"
                            onChange={handleFileChange}
                            accept=".xlsx"
                        />
                        {selectedFile && (
                            <div className="selected-file-name">{selectedFile.name}</div>
                        )}
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <Button
                            onClick={submitMaterial}
                            disabled={isLoading}
                            loading={isLoading}
                            size="large"
                            type="primary"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PosmAllocation;
