/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Select, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import {
    useGetPendingTransferRequestQuery,
    useGetTownListQuery,
    useTransferApproveOrRejectMutation,
} from '../../redux/features/posmManagement/posmManagementApi';
import NoResult from '../../ui/NoResult';
import firebaseLog from '../../util/firebaseLog';

const { Column, ColumnGroup } = Table;
const { Option } = Select;

function ApproveRejectTransfer() {
    const dispatch = useDispatch();

    // get town list
    const { data, isLoading } = useGetTownListQuery();

    const [townId, setTownId] = useState('');
    const [skip, setSkip] = useState(false);

    const {
        data: pendingData,
        isLoading: pendingLoading,
        refetch,
    } = useGetPendingTransferRequestQuery(
        {
            id: townId,
        },
        { skip }
    );

    useEffect(() => {
        if (townId) {
            setSkip(false);
            refetch();
        }
    }, [refetch, townId]);

    const [transferApproveOrReject, { isLoading: actionLoading }] =
        useTransferApproveOrRejectMutation();

    // approve or reject
    const approveOrRejectAction = (id, status) => {
        confirmAlert({
            title: `Confirm to ${status}`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const transferData = {
                            movementId: id,
                            material: pendingData.data
                                .find((x) => x.movementId === id)
                                .materials.map(
                                    ({
                                        material: {
                                            id: materialId,
                                            name: materialName,
                                            category: materialCategory,
                                            company: materialCompany,
                                            quantity,
                                        },
                                    }) => ({
                                        id: materialId,
                                        name: materialName,
                                        category: materialCategory,
                                        company: materialCompany,
                                        quantity,
                                    })
                                ),
                            status,
                        };
                        try {
                            const result = await transferApproveOrReject({
                                ...transferData,
                            }).unwrap();
                            message.success('Successfully complete', 10);
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    // data receive and set global loading
    useEffect(() => {
        const loading = pendingLoading || actionLoading;
        dispatch(setGlobalLoading(loading));
    }, [dispatch, pendingLoading, actionLoading]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Transfer Request', user: user.name });
    }, [user.name]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <div style={{ margin: '15px' }}>
            <HelmetHeader title="Approve Reject Transfer" />

            <div
                style={{
                    padding: '10px',
                    marginTop: '8px',
                    boxShadow: '0 0 5px 0 #d6d0d0',
                    borderRadius: '10px',
                }}
            >
                <p style={{ fontSize: '16px', fontWeight: 500 }}>Approve/Reject Transfer Request</p>

                <div style={{ marginBottom: '10px' }}>
                    <Space>
                        <div>
                            <label htmlFor="town" className="required-label">
                                From Town Name
                            </label>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <Select
                                    allowClear
                                    showSearch
                                    size="large"
                                    id="town"
                                    placeholder="Select a Town"
                                    style={{ minWidth: '220px' }}
                                    options={data?.data}
                                    value={townId || null}
                                    onChange={(e) => {
                                        setTownId(e);
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    searchValue={search}
                                    onSearch={onSearch}
                                />
                            </div>
                        </div>
                    </Space>
                </div>

                {pendingData?.data?.length ? (
                    pendingData?.data?.map((item) => (
                        <React.Fragment key={item.movementId}>
                            <Table
                                rowKey="id"
                                scroll={{ x: 800 }}
                                pagination={false}
                                size="small"
                                dataSource={
                                    item?.materials?.map(
                                        ({
                                            transferTime,
                                            from: { name: formTownName },
                                            to: { name: toTownName },
                                            material: {
                                                name: materialName,
                                                category: categoryName,
                                                quantity,
                                            },
                                        }) => ({
                                            id: crypto.randomUUID(),
                                            transferTime,
                                            formTownName,
                                            toTownName,
                                            materialName,
                                            categoryName,
                                            quantity,
                                        })
                                    ) || []
                                }
                            >
                                <Column
                                    title="Timestamp"
                                    dataIndex="transferTime"
                                    key="transferTime"
                                    render={(v) => dayjs(v).format('DD/MM/YYYY hh:mm:ss A')}
                                />
                                <Column
                                    title="From Town"
                                    dataIndex="formTownName"
                                    key="formTownName"
                                />
                                <Column
                                    title="Receiver Town"
                                    dataIndex="toTownName"
                                    key="toTownName"
                                />
                                <Column
                                    title="Material Name"
                                    dataIndex="materialName"
                                    key="materialName"
                                />
                                <Column
                                    title="Category"
                                    dataIndex="categoryName"
                                    key="categoryName"
                                />
                                <Column title="Quantity" dataIndex="quantity" key="quantity" />
                            </Table>

                            <Space
                                style={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    margin: '8px 0',
                                }}
                            >
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={() =>
                                        approveOrRejectAction(item.movementId, 'Approve')
                                    }
                                >
                                    Approve
                                </Button>
                                <Button
                                    size="large"
                                    type="primary"
                                    danger
                                    onClick={() =>
                                        approveOrRejectAction(item.movementId, 'Decline')
                                    }
                                >
                                    Reject
                                </Button>
                            </Space>
                        </React.Fragment>
                    ))
                ) : (
                    <NoResult />
                )}
            </div>
        </div>
    );
}

export default ApproveRejectTransfer;
