/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import { Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import { useAddNoticeMutation, useGetNoticeMutation } from '../../redux/features/notice/noticeApi';
import { resetNoticeFilter } from '../../redux/features/notice/noticeFilterSlice';
import firebaseLog from '../../util/firebaseLog';
import NoticeTable from './NoticeTable';

const { Column, ColumnGroup } = Table;

function Notice() {
    // accessToken
    const { accessToken } = useSelector((state) => state.auth);

    const [getNotice, { data, isLoading, isError, error: errorData }] = useGetNoticeMutation({});
    const [addNotice, { data: addData, isLoading: noticePostLoading }] = useAddNoticeMutation();

    // dispatch
    const dispatch = useDispatch();

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    // notice post data
    const { publishDate, expireDate, description, target } = useSelector(
        (state) => state.noticeFilter
    );

    // loading state
    const [loading, setLoading] = useState(false);

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetNoticeFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, data, addData]);

    // generated request body data
    const getInventoryQueryData = () => {
        const bodyData = {};
        if (circle.length) {
            bodyData.circleId = circle.map((item) => item.value);
        }
        if (region.length) {
            bodyData.regionId = region.map((item) => item.value);
        }
        if (area.length) {
            bodyData.areaId = area.map((item) => item.value);
        }
        if (territory.length) {
            bodyData.territoryId = territory.map((item) => item.value);
        }
        if (town.length) {
            bodyData.townId = town.map((item) => item.value);
        }
        return bodyData;
    };

    const submitANotice = () => {
        if (circle.length || region.length || area.length || territory.length || town.length) {
            if (publishDate && expireDate) {
                if (description) {
                    if (target.length) {
                        addNotice({
                            ...getInventoryQueryData(),
                            description,
                            publishAt: publishDate,
                            expireAt: expireDate,
                            targetOf: target,
                        });
                    } else {
                        message.error('Please select a target');
                    }
                } else {
                    message.error('Please type your notice');
                }
            } else {
                message.error('Please select publish date and expire date');
            }
        } else {
            message.error('You must select the place where you want to give notice.');
        }
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Notice', user: user.name });
    }, [user.name]);

    useEffect(() => {
        getNotice({ page: 1, limit: 100 });
    }, [getNotice, addData]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Notice" />

            <div style={{ margin: '16px 0' }}>
                <Filter loading={noticePostLoading} queryFunc={submitANotice} pathname="/notice" />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Notice</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <NoticeTable loading={isLoading} noticeData={data?.data} />
                </div>
            </div>
        </>
    );
}

export default Notice;
