import { Button, Image, Table, message } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import thumb from '../../assets/thumb.png';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import { useGetMaterialQuery } from '../../redux/features/materialManagement/materialManagementApi';
import { resetPosmViewListFilter } from '../../redux/features/posmManagement/posmViewFilterSlice';
import firebaseLog from '../../util/firebaseLog';

function ViewPosmList() {
    const dispatch = useDispatch();
    // filter hook
    const { posmName, posmType, posmBrand, posmChannel, posmCode } = useSelector(
        (state) => state.posmViewFilter
    );

    const [page, setPage] = useState(1);

    const { data, isLoading, refetch, isFetching } = useGetMaterialQuery({
        page,
        limit: 10000,
        category: posmType,
        name: posmName,
        company: posmBrand,
        channel: posmChannel,
        code: posmCode,
    });

    const headers = [
        { label: 'Owner Name', key: 'company' },
        { label: 'POSM Name', key: 'name' },
        { label: 'POSM Category', key: 'category' },
        { label: 'AI Report Mode', key: 'isCount' },
        { label: 'SOV Mode', key: 'sovMode' },
    ];
    const doc = data?.data?.data?.map(({ company, name, category, isCount, sovMode }) => ({
        company,
        name,
        category,
        isCount: isCount ? 'Count' : 'Yes/No',
        sovMode: sovMode ? 'Yes' : 'No',
    }));

    // search posm data
    const searchData = () => {
        refetch();
    };

    // reset existing filter
    useEffect(() => {
        dispatch(resetPosmViewListFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'View POSM List', user: user.name });
    }, [user.name]);

    // download button
    async function downLoadingFile(docs) {
        const excName = docs?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await docs.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    // function getDownloadBodyData({ dArr, ffLev }) {
    //     const bodyData = {
    //         index: 0,
    //     };
    //     if (dArr) {
    //         bodyData.startDate = dayjs(dArr).startOf('day');
    //         bodyData.endDate = dayjs(dArr).endOf('day');
    //     }
    //     return bodyData;
    // }

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    index: 0,
                    // ...getDataManagementFilterData({ region, area, territory, town }),
                    // ...getDownloadBodyData({ dArr: date }),
                    // eslint-disable-next-line no-plusplus
                }),
                mode: 'cors',
            });

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            {/* /* page title and description */}
            <HelmetHeader title="View POSM List" />
            <div style={{ margin: '16px 0' }}>
                <Filter loading={isLoading} queryFunc={searchData} pathname="/view-posm-list" />
            </div>
            <div style={{ textAlign: 'right', padding: '5px' }}>
                <Button
                    onClick={() => {
                        download({
                            url: '/v1/report/posm-list',
                            fileName: 'POSM List.xlsx',
                        });
                    }}
                    loading={isLoading}
                    danger
                    type="primary"
                >
                    Download
                    {/* <CSVLink filename="AI-POSM-List.csv" data={doc || []} headers={headers}>
                        Download
                    </CSVLink> */}
                </Button>
            </div>
            <Table
                size="small"
                rowKey="_id"
                loading={isLoading}
                dataSource={data?.data?.data || []}
                scroll={{ x: 800 }}
            >
                {/* <Column title="POSM ID" dataIndex="materialCode" key="materialCode" /> */}
                <Column title="POSM Name" dataIndex="name" key="name" />
                <Column title="POSM Type" dataIndex="category" key="category" />
                <Column title="Channel" dataIndex="channel" key="channel" />
                <Column title="Brand Name" dataIndex="company" key="company" />
                <Column title="POSM Code" dataIndex="materialCode" key="materialCode" />
                <Column
                    align="center"
                    title="Sample Picture"
                    dataIndex="samplePicture"
                    key="samplePicture"
                    render={(_, record) => (
                        <Image
                            width={30}
                            src={thumb}
                            preview={{
                                src: record?.image?.original,
                            }}
                        />
                    )}
                />
            </Table>
        </>
    );
}

export default ViewPosmList;
