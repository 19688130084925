import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import appLogo from '../assets/logo-portal.svg';
import { items } from '../data/menuData';
import { useWhoAmIQuery } from '../redux/features/auth/authApi';

const { Header, Content, Footer, Sider } = Layout;

function Sidebar() {
    // navbar collapsed hook
    const [collapsed, setCollapsed] = useState(false);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        if (windowSize.width <= 1024) {
            setCollapsed(true);
        }
    }, [windowSize.width]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array to run effect only once on mount

    const { accessToken } = useSelector((state) => state.auth);

    const [skip, setSkip] = useState(true);

    const { data, isLoading, refetch } = useWhoAmIQuery({}, { skip });

    const permissionSet = new Set(data?.data?.permission.map(({ label }) => label));

    const resultArray2 = items
        .reduce((acc, curr) => {
            if (permissionSet.has(curr.label)) {
                const children = curr.children?.filter(({ label }) => permissionSet.has(label));
                return [...acc, { ...curr, children }];
            }
            return acc;
        }, [])
        ?.sort((a, b) => a.serial - b.serial);

    const resultArray = resultArray2.length ? resultArray2 : undefined;

    // navigate hook
    const navigate = useNavigate();

    if (resultArray?.length <= 0) {
        navigate('/un-authorized');
    }

    useEffect(() => {
        setSkip(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div
                style={{
                    padding: '5px',
                    textAlign: 'center',
                    transition: 'all 0.05s ease in out',
                }}
            >
                <Link to="/">
                    <div
                        style={{
                            background: '#FFFFFF',
                            width: collapsed ? '100%' : '90%',
                            margin: '0 auto',
                            borderRadius: '5px',
                            padding: '8px 2px',
                        }}
                    >
                        <img
                            src={appLogo}
                            alt="Logo"
                            style={{
                                width: collapsed ? '100%' : '50%',
                                borderRadius: '3px',
                            }}
                        />
                    </div>
                </Link>
            </div>
            <Menu
                theme="dark"
                style={{ paddingBottom: '50px' }}
                onClick={({ key }) => navigate(key)}
                selectedKeys={window.location.pathname}
                defaultSelectedKeys={[window.location.pathname]}
                mode="inline"
                items={resultArray}
            />
        </Sider>
    );
}

export default Sidebar;
