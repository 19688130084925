/* eslint-disable react/no-array-index-key */
import { Col, Image, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import AttendanceItemSkeleton from '../../ui/AttendanceItemSkeleton';
import NoResult from '../../ui/NoResult';
import labelChange from '../../util/labelChange';

function AttendanceTracker({ data, loading }) {
    return (
        <div className="component-box-container">
            <div className="box-heading" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                Attendance Tracker
            </div>

            <div style={{ padding: '10px', width: '100%' }}>
                {loading ? (
                    <AttendanceItemSkeleton />
                ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {data?.length ? (
                            <Row gutter={[5, 10]}>
                                <Image.PreviewGroup
                                    preview={{
                                        countRender: (current) =>
                                            `${data[current - 1]?.user.name} - ${data[current - 1]?.user.usercode} ( ${
                                                data[current - 1]?.lat
                                            }, ${data[current - 1]?.lon} )`,
                                    }}
                                >
                                    {data?.map(({ user, image, punchInAt, withinRadius }, i) => (
                                        <Col key={user.id} lg={6}>
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    background: `${
                                                        !withinRadius ? '#FF5150' : '#fff'
                                                    }`,
                                                    borderRadius: '5px',
                                                    padding: '5px',
                                                }}
                                            >
                                                <Image
                                                    width={80}
                                                    height={80}
                                                    style={{ borderRadius: '50%' }}
                                                    preview={{ src: image.original || '' }}
                                                    src={image.thumb || ''}
                                                />
                                                <h5
                                                    style={{
                                                        color: `${!withinRadius ? '#fff' : '#000'}`,
                                                        margin: '10px 0 0 0',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {user.name}
                                                </h5>
                                                <h5
                                                    style={{
                                                        color: `${!withinRadius ? '#fff' : '#000'}`,
                                                        margin: '0',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {labelChange(user.usercode)}
                                                </h5>
                                                <h5
                                                    style={{
                                                        color: `${!withinRadius ? '#fff' : '#000'}`,
                                                        margin: '0',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {labelChange(user.kind)}
                                                </h5>
                                                <p
                                                    style={{
                                                        color: `${!withinRadius ? '#fff' : '#000'}`,
                                                        margin: 0,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {dayjs(punchInAt).format('hh:mm:ss A')}
                                                </p>
                                            </div>
                                        </Col>
                                    ))}
                                </Image.PreviewGroup>
                            </Row>
                        ) : (
                            <NoResult />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default AttendanceTracker;
