/* eslint-disable no-underscore-dangle */
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import pdfIcon from '../../assets/pdf.png';
import imageIcon from '../../assets/thumb.png';
import videoIcon from '../../assets/video.png';
import { useDeleteTrainingModuleMutation } from '../../redux/features/trainingModule/trainingModuleApi';
import labelChange from '../../util/labelChange';

const { Column, ColumnGroup } = Table;

function TrainingModuleDataTable({ loading, data }) {
    const [deleteTrainingModule, { isLoading }] = useDeleteTrainingModuleMutation();

    // delete training module
    const deleteNotice = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteTrainingModule(id);
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.info('Cancelled'),
                },
            ],
        });
    };

    function getIcon(type) {
        let icon = null;
        if (type === 'pdf') {
            icon = pdfIcon;
        }
        if (type === 'png' || type === 'jpg' || type === 'jpeg') {
            icon = imageIcon;
        }
        if (type === 'mp4') {
            icon = videoIcon;
        }
        return icon;
    }

    return (
        <Table
            size="small"
            scroll={{ x: 800 }}
            rowKey="_id"
            loading={loading || isLoading}
            pagination={false}
            dataSource={data}
        >
            <Column
                title="Content Type"
                key="fileType"
                render={(_, record) => (
                    <img width="45px" alt="icon" src={getIcon(record.fileType)} />
                )}
            />
            <Column title="Name" dataIndex="fileName" key="fileName" />
            <Column
                title="Uploaded Date & Time"
                dataIndex="createdAt"
                key="createdAt"
                render={(_, record) => dayjs(record.createdAt).format('DD-MM-YYYY hh:mm A')}
            />
            <Column
                title="Publish Date"
                dataIndex="publishAt"
                key="publishAt"
                render={(_, record) => dayjs(record.publishAt).format('DD-MM-YYYY')}
            />
            <Column
                title="Expire Date"
                dataIndex="expireAt"
                key="expireAt"
                render={(_, record) => dayjs(record.expireAt).format('DD-MM-YYYY')}
            />
            <Column
                title="Target"
                dataIndex="targetOf"
                key="targetOf"
                render={(_, record) => record.targetOf?.map((x) => labelChange(x)).join(' ')}
            />
            <Column
                title="Action"
                key="action"
                align="right"
                render={(_, record) => (
                    <Space size="middle">
                        <Button
                            onClick={() => deleteNotice(record._id)}
                            size="small"
                            icon={<DeleteOutlined />}
                            type="primary"
                            danger
                            shape="circle"
                        />
                        <a
                            href={record.fileUrl}
                            download="file.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DownloadOutlined size={60} />
                        </a>
                    </Space>
                )}
            />
        </Table>
    );
}

export default TrainingModuleDataTable;
