import { Button, Col, Input, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import {
    useCreateNewGroupMutation,
    useGetPermissionTableDataQuery,
} from '../../redux/features/superuser/superUserApi';
import firebaseLog from '../../util/firebaseLog';
import PermissionTable from './PermissionTable';

function AddGroup() {
    // get permissions for groups
    const { data, isLoading } = useGetPermissionTableDataQuery();

    const { ids } = useSelector((state) => state.permissionsIds);

    const idsArr = ids
        .filter((obj, i) => ids.findIndex((x) => x.value === obj.value) === i)
        .filter(({ value }) => typeof value === 'string')
        .map(({ value }) => value);

    const [groupName, setGroupName] = useState('');

    const groupData = data?.data.map((items, i) =>
        items.length > 0
            ? {
                  key: i,
                  name: items[0].label,
                  value: i,
                  children: items.map(({ label, value }) => ({
                      key: value,
                      name: label,
                      value,
                  })),
              }
            : { name: items[0].name, value: items[0].value, key: items[0].value }
    );

    const [createNewGroup] = useCreateNewGroupMutation();

    const createGroup = async () => {
        if (groupName && idsArr.length) {
            try {
                const result = await createNewGroup({
                    permissions: [...idsArr],
                    name: groupName,
                });
                setGroupName('');
                message.success('Group created successfully');
            } catch (error) {
                message.error('Error while creating group');
            }
        } else {
            message.error('Name and permissions are required');
        }
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Add Group', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Add Group" />

            <div
                style={{
                    marginTop: '15px',
                    boxShadow: '0 0 5px 0 #e9e6e6',
                    borderRadius: '10px',
                    padding: '10px',
                }}
            >
                <div>
                    <h3>Add Group</h3>
                    <div style={{ margin: '10px 0' }}>
                        <p style={{ margin: '3px 0' }}>
                            Group Name <sup style={{ color: 'red' }}>*</sup>
                        </p>
                        <Input
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            style={{ maxWidth: '220px' }}
                            size="large"
                            placeholder="Group Name"
                        />
                    </div>
                </div>
                <Row gutter={[10, 10]}>
                    {groupData?.map((item) => (
                        <Col>
                            <PermissionTable data={[item]} />
                        </Col>
                    ))}
                </Row>
                <div style={{ margin: '10px 0' }}>
                    <Button
                        onClick={createGroup}
                        size="large"
                        style={{ background: '#faad14', color: '#fff' }}
                    >
                        Create
                    </Button>
                </div>
            </div>
        </>
    );
}
export default AddGroup;
