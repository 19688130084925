/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    route: '',
    outletCode: '',
    channel: '',
    outletType: [],
};

const pjpMappingFilterSlice = createSlice({
    name: 'pjpMappingFilterSlice',
    initialState,
    reducers: {
        setRoute: (state, action) => {
            state.route = action.payload;
        },
        setOutletCode: (state, action) => {
            state.outletCode = action.payload;
        },
        setChannel: (state, action) => {
            state.channel = action.payload;
        },
        setOutletType: (state, action) => {
            state.outletType = action.payload;
        },
        resetPjpStatusFilter: (state, action) => {
            state.route = '';
            state.outletCode = '';
            state.channel = '';
            state.outletType = [];
        },
    },
});

export default pjpMappingFilterSlice.reducer;
export const { setChannel, setOutletCode, setOutletType, setRoute, resetPjpStatusFilter } =
    pjpMappingFilterSlice.actions;
