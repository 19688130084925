/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import { Progress } from 'antd';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';

function RenderFormatValue({ val, label }) {
    return (
        <>
            <span
                style={{
                    display: 'block',
                    fontWeight: 800,
                    color: '#4d4d4d',
                    marginBottom: '-10px',
                }}
            >
                <CountUp end={val} />%
            </span>
            <span style={{ fontSize: '12px', fontWeight: 400 }}>{label}</span>
        </>
    );
}

function ProgressCircle({
    size = 'small',
    data = 0,
    colors = {
        '0%': '#8464EE',
        '100%': '#697BFD',
    },
    label = '',
    margin = 0,
}) {
    return (
        <div style={{ display: 'block', marginRight: margin, textAlign: 'center' }}>
            <Progress
                animation="true"
                size={size}
                type="circle"
                status="active"
                percent={data}
                strokeWidth={12}
                strokeColor={colors}
                // eslint-disable-next-line react/no-unstable-nested-components
                format={(percent) => <RenderFormatValue val={percent} label={label} />}
            />
        </div>
    );
}

export default ProgressCircle;
