import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../../components/CommonButton';
import {
    setOutletCode,
    setQuarter,
    setStatus,
} from '../../redux/features/backlit/backlitFilterSlice';

function BacklitFilter({ queryFunc, loading, downloadButton }) {
    const { quarter, status, outletCode } = useSelector((state) => state.backlitFilter);

    const dispatch = useDispatch();

    const onChange = (_, dateString) => {
        dispatch(setQuarter(dateString));
    };

    // user information
    const { user } = useSelector((state) => state.auth);

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={outletCode}
                    placeholder="Outlet Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setOutletCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    onChange={onChange}
                    picker="quarter"
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setStatus(e))}
                    options={[
                        {
                            value: 'Pending',
                            label: 'Pending',
                        },
                        {
                            value: 'Approved',
                            label: 'Approved',
                        },
                        {
                            value: 'Edited',
                            label: 'Edited',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <CommonButton
                    loading={loading}
                    queryFunc={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<DownloadOutlined />}
                    danger
                    type="primary"
                    onClick={() => {
                        downloadButton({
                            url: '/v1/report/backlit-audit',
                            fileName: 'Backlit Audit Report.xlsx',
                        });
                    }}
                >
                    Download
                </Button>
            </Col>
        </>
    );
}

export default BacklitFilter;
