/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Col, Form, Input, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ublLogo from '../assets/app_logo.png';
import HelmetHeader from '../components/HelmetHeader';
import routeData from '../data/routeData';
import { useLoginMutation } from '../redux/features/auth/authApi';

const { Title } = Typography;
function Login() {
    const [login, { data, isLoading, error: responseError }] = useLoginMutation();
    const [errorMessage, setErrorMessage] = useState('');

    const [geoLocation, setGeoLocation] = useState({ lat: null, lon: null });

    const navigate = useNavigate();

    const [isPassReset, setIsPassReset] = useState(false);
    const [isPrivileged, setIsPrivileged] = useState(false);

    useEffect(() => {
        if (responseError?.data?.message) {
            setErrorMessage(responseError.data.message);
        }
        if (errorMessage) {
            message?.error(errorMessage || 'Something went wrong');
        }
        if (data?.data.access_token && data?.data.payload) {
            const findRoute = routeData.find(
                (item) => item.name === data?.data?.payload.landingPage.label
            );
            message.success(data?.data?.message);
            navigate(findRoute.path);
            window.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, responseError, errorMessage]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setGeoLocation({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    });
                },
                (error) => {
                    message.error('Geolocation is not enabled. Please enable to continue.');
                }
            );
        } else {
            message.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const onFinish = async (values) => {
        if (!geoLocation.lat || !geoLocation.lon) {
            message.error('Unable to retrieve location. Please try again.');
            return;
        }

        setErrorMessage('');
        login({ ...values, ...geoLocation, loggedOn: 'Web' });
    };

    return (
        <>
            <HelmetHeader title="Login" />

            <Row>
                <Col span={12} className="login-left-site-container">
                    <div className="login-left-site">
                        <div style={{ textAlign: 'center' }}>
                            <p className="login-semi-title">Nice to see you again</p>
                            <h1 className="login-title">WELCOME BACK</h1>
                            <div
                                style={{
                                    width: '40px',
                                    height: '5px',
                                    background: 'white',
                                    margin: '0 auto',
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="login-form-container">
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <div style={{ textAlign: 'center', marginBottom: '35px' }}>
                                <img alt="ubl logo" src={ublLogo} width={150} />
                                <Title level={3}>Login</Title>
                            </div>
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Username!',
                                    },
                                ]}
                            >
                                <Input
                                    className="login-input-field-customize"
                                    placeholder="Username"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                            >
                                <Input
                                    className="login-input-field-customize"
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={isLoading}
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    Sign In
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Login;
