import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import firebaseLog from '../../util/firebaseLog';
import DataTable from './DataTable';

function DataManagement() {
    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Data Management', user: user.name });
    }, [user.name]);
    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="View Town" />

            <div style={{ marginTop: '10px' }}>
                <div>
                    <div className="box-heading">Town List</div>
                    <DataTable />
                </div>
            </div>
        </>
    );
}

export default DataManagement;
