import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import {
    useGetPendingLeaveMutation,
    useUpdateLeaveRequestMutation,
} from '../../redux/features/leaveManagement/leaveManagementApi';
import { resetLeaveManagementFilter } from '../../redux/features/leaveManagement/leaveManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import debounce from '../../util/debounce';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import labelChange from '../../util/labelChange';
import { capitalizeFirstLetter } from '../../util/miniFunction';

const { Column, ColumnGroup } = Table;

function LeaveManagement() {
    const dispatch = useDispatch();

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { dateRange, empLevel, ffId } = useSelector((state) => state.leaveManagementFilter);

    const [getPendingLeave, { data: leaveData, isLoading }] = useGetPendingLeaveMutation();

    const [updateLeaveRequest, { data: updateData, isLoading: updateLoading }] =
        useUpdateLeaveRequestMutation();

    function generateBody(d, lev, id) {
        const bodyData = {};
        if (d.length) {
            const [s, e] = d;
            bodyData.from = dayjs(s).toJSON();
            bodyData.to = dayjs(e).toJSON();
        }
        if (lev) {
            bodyData.userLevel = empLevel;
        }
        if (id) {
            bodyData.userId = id;
        }
        return bodyData;
    }

    const fetchLeaveData = () => {
        getPendingLeave({
            page: 1,
            limit: 1000,
            ...generateBody(dateRange, empLevel, ffId),
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
        });
    };

    const updateLeaveRequestFunc = (record, status) => {
        confirmAlert({
            title: `Confirm to ${status}`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: debounce(async () => {
                        try {
                            const result = await updateLeaveRequest({
                                leaveId: record.id,
                                userId: record.userId,
                                day: record.day,
                                status,
                                modifiedOn: 'Portal',
                            }).unwrap();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    }, 200),
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    useEffect(() => {
        fetchLeaveData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetLeaveManagementFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Leave Management', user: user.name });
    }, [user.name]);

    const leaveDataNormalize = leaveData?.data?.map(
        ({
            kind,
            entitled,
            consumed,
            remaining,
            user: { name: empName, _id: userId, userLevel },
            leave: { startAt, endAt, day, comment, _id },
            createdAt,
        }) => ({
            userId,
            id: _id,
            leaveType: kind,
            entitled,
            consumed,
            remaining,
            empName,
            startAt,
            endAt,
            day,
            comment,
            userLevel,
            createdAt,
        })
    );

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Leave Management" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    queryFunc={fetchLeaveData}
                    loading={isLoading}
                    pathname="/leaveManagement"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Leave Management</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        rowKey="id"
                        loading={isLoading}
                        dataSource={leaveDataNormalize || []}
                        pagination={false}
                    >
                        <Column
                            title="Details"
                            key="name"
                            render={(_, record) => (
                                <>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Name: </span>
                                        {record.empName}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Employee Level : </span>
                                        {labelChange(record.userLevel)}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Request Date: </span>
                                        {dayjs(record.createdAt).format('DD/MM/YYYY')}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Start At: </span>
                                        {dayjs(record.startAt).format('DD/MM/YYYY')}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>End At: </span>
                                        {dayjs(record.endAt).format('DD/MM/YYYY')}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Total Days: </span>
                                        {record.day}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Leave type: </span>
                                        {capitalizeFirstLetter(record.leaveType)}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Comments: </span>
                                        {record.comment}
                                    </p>
                                </>
                            )}
                        />
                        <Column title="Total Entitled" dataIndex="entitled" key="entitled" />
                        <Column title="Total Consume" dataIndex="consumed" key="consumed" />
                        <Column title="Remaining Leave" dataIndex="remaining" key="remaining" />
                        <Column
                            title="Action"
                            key="action"
                            render={(_, record) => (
                                <Space>
                                    <Button
                                        onClick={() => updateLeaveRequestFunc(record, 'Approve')}
                                        type="primary"
                                        shape="circle"
                                        icon={<CheckOutlined />}
                                    />
                                    <Button
                                        onClick={() => updateLeaveRequestFunc(record, 'Decline')}
                                        type="primary"
                                        shape="circle"
                                        danger
                                        icon={<CloseOutlined />}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </div>
            </div>
        </>
    );
}

export default LeaveManagement;
