import { Footer } from 'antd/es/layout/layout';
import React from 'react';
import logo from '../assets/logo.svg';

function FooterComponent() {
    const width = window.innerWidth;
    return (
        <Footer
            size="small"
            theme="dark"
            style={{
                textAlign: 'center',
                padding: '16px 50px',
                background: '#ffffff',
                color: '#000',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                position: 'relative',
                fontSize: width < 750 ? '10px' : '',
            }}
        >
            Powered By © HawkEyes Digital Monitoring Ltd. All rights reserved.
            {width < 750 ? null : (
                <div style={{ position: 'absolute', right: '12px', bottom: '3px' }}>
                    <img
                        src={logo}
                        alt="HawkEyes"
                        width="115px"
                        style={{ borderRadius: '2px', textAlign: 'right' }}
                    />
                </div>
            )}
        </Footer>
    );
}

export default FooterComponent;
