import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import {
    setDateRange,
    setEmail,
    setName,
} from '../../redux/features/loginDetails/loginDetailsFilterSlice';

function LoginDetailsFilter({ queryFunc, loading }) {
    const { dateRange, name, email } = useSelector((state) => state.loginDetailsFilter);

    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={name}
                    placeholder="Name"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setName(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={email}
                    placeholder="Email"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 100, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default LoginDetailsFilter;
