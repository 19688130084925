import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import { useGetPosmListQuery } from '../../redux/features/inventory/inventoryApiSlice';
import { useGetMaterialQuery } from '../../redux/features/materialManagement/materialManagementApi';
import {
    setAiRun,
    setAuditStatus,
    setCallType,
    setChannel,
    setDateRange,
    setDisplayChallengeStatus,
    setDisplayStatus,
    setExecutionDone,
    setFFCode,
    setFFLevel,
    setFFName,
    setFatType,
    setOutletCode,
    setOutletStatusByAi,
    setOutletType,
    setPosmCode,
    setPosmName,
    setQpdsStatus,
    setShelfTalkerChallengeStatus,
    setShelfTalkerType,
    setTask,
} from '../../redux/features/report/visitCallFilterSlice';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import labelChange from '../../util/labelChange';

function VisitCallFilter({ queryFunc, loading }) {
    const { outletCode, tmsCode, errorReason, callType, tmrCode, ffLevel, posmCode } = useSelector(
        (state) => state.visitCallFilter
    );

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const dispatch = useDispatch();

    // get material info
    // get material name
    const { data, isLoading: materialGetLoading } = useGetPosmListQuery();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    const getFilterData = (lev) => {
        const bodyData = {};
        if (lev) {
            bodyData.type = lev;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(ffLevel),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, town, ffLevel]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    const isEditableUser = !!userInfo?.permission?.find((x) => x.label === 'User Edit')?.label;

    const projectAccessData = userInfo?.projectAccess
        ?.map((x) => ({
            label: labelChange(x),
            value: x,
        }))
        ?.filter((x) => x.label !== 'MTM');

    const {
        data: posmData,
        refetch,
        isFetching,
    } = useGetMaterialQuery({
        limit: 10000,
    });
    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Task"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setTask(e))}
                    options={[
                        {
                            value: 'DA',
                            label: 'Display Audit',
                        },
                        {
                            value: 'POSM',
                            label: 'POSM Execution',
                        },
                        {
                            value: 'QPDS',
                            label: 'QPDS',
                        },
                        {
                            value: 'FAT',
                            label: 'FAT',
                        },
                        {
                            value: 'SOS',
                            label: 'SOS',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    value={ffLevel}
                    mode="multiple"
                    allowClear
                    placeholder="FF Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFLevel(e))}
                    options={projectAccessData?.filter((x) => x.label !== 'MTCM') || []}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="FF Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFName(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={tmrCode}
                    placeholder="FF Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setFFCode(e.target.value))}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Error Reason"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setErrorReason(e))}
                    options={[
                        {
                            value: 'qrError',
                            label: 'QR code not match',
                        },
                        {
                            value: 'location',
                            label: 'Location not match',
                        },
                    ]}
                />
            </Col> */}

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setErrorReason(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Visit Call Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setCallType(e))}
                    options={[
                        {
                            value: 'Schedule Covered',
                            label: 'Schedule Covered',
                        },
                        {
                            value: 'Pending Covered',
                            label: 'Pending Covered',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Channel"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setChannel(e))}
                    options={[
                        {
                            value: 'UNG',
                            label: 'UNG',
                        },
                        {
                            value: 'UWMG',
                            label: 'UWMG',
                        },
                        {
                            value: 'UHPCT',
                            label: 'UHPCT',
                        },
                        {
                            value: 'UCS',
                            label: 'UCS',
                        },
                        {
                            value: 'UGS',
                            label: 'UGS',
                        },
                        {
                            value: 'DS',
                            label: 'DS',
                        },
                        {
                            value: 'WS',
                            label: 'WS',
                        },
                        {
                            value: 'OH',
                            label: 'OH',
                        },
                        {
                            value: 'HOTEL',
                            label: 'HOTEL',
                        },
                        {
                            value: 'E-COMM',
                            label: 'E-COMM',
                        },
                        {
                            value: 'CDO',
                            label: 'CDO',
                        },
                        {
                            value: 'FOOD',
                            label: 'FOOD',
                        },
                        {
                            value: 'RWMG',
                            label: 'RWMG',
                        },
                        {
                            value: 'RNG',
                            label: 'RNG',
                        },
                        {
                            value: 'RCS',
                            label: 'RCS',
                        },
                        {
                            value: 'PGS',
                            label: 'PGS',
                        },
                        {
                            value: 'POLLYDUT',
                            label: 'POLLYDUT',
                        },
                        {
                            value: 'CDOW',
                            label: 'CDOW',
                        },
                        {
                            value: 'RMG STORES',
                            label: 'RMG STORES',
                        },
                        {
                            value: 'RMGGROCER',
                            label: 'RMGGROCER',
                        },
                        {
                            value: 'SSS',
                            label: 'SSS',
                        },
                        {
                            value: 'CDOM',
                            label: 'CDOM',
                        },
                    ]}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="POSM Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmId(e))}
                    // eslint-disable-next-line no-underscore-dangle
                    options={data?.data?.data?.map((x) => ({ label: x.name, value: x._id }))}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Outlet Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletType(e))}
                    options={[
                        {
                            value: 'BS',
                            label: 'BS',
                        },
                        {
                            value: 'NS',
                            label: 'NS',
                        },
                        {
                            value: 'QPDS',
                            label: 'QPDS',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={outletCode}
                    placeholder="Outlet Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setOutletCode(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status for Display"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setDisplayStatus(e))}
                    options={[
                        {
                            value: 'Passed',
                            label: 'Passed',
                        },
                        {
                            value: 'Failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status for QPDS"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setQpdsStatus(e))}
                    options={[
                        {
                            value: 'Passed',
                            label: 'Passed',
                        },
                        {
                            value: 'Failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status by AI"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletStatusByAi(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status by CM Sup"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletStatusByMs(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Execution Done"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setExecutionDone(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Fat Asset Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFatType(e))}
                    options={[
                        {
                            value: 'Backlit',
                            label: 'Backlit',
                        },
                        {
                            value: 'Nonlit',
                            label: 'Nonlit',
                        },
                        {
                            value: 'Distribution Board',
                            label: 'Distribution Board',
                        },
                        {
                            value: 'Mega Hanger',
                            label: 'Mega Hanger',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Shelf Talker Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setShelfTalkerType(e))}
                    options={[
                        {
                            value: 'METRO',
                            label: 'METRO',
                        },
                        {
                            value: 'RURAL',
                            label: 'RURAL',
                        },
                        {
                            value: 'URBAN',
                            label: 'URBAN',
                        },
                        {
                            value: 'NON-LIT',
                            label: 'NON-LIT',
                        },
                        {
                            value: 'PVC LIT',
                            label: 'PVC LIT',
                        },
                        {
                            value: 'LIT',
                            label: 'LIT',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="POSM Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmName(e))}
                    // eslint-disable-next-line no-underscore-dangle
                    options={posmData?.data?.data?.map((x) => ({ label: x.name, value: x.name }))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={posmCode}
                    placeholder="POSM Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setPosmCode(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Display Challenge Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setDisplayChallengeStatus(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Shelf Talker Challenge Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setShelfTalkerChallengeStatus(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Audit Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setAuditStatus(e))}
                    options={[
                        {
                            value: 'Pending',
                            label: 'Pending',
                        },
                        {
                            value: 'Done',
                            label: 'Done',
                        },
                    ]}
                />
            </Col>

            {isEditableUser ? (
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Select
                        allowClear
                        placeholder="AI Run"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setAiRun(e))}
                        options={[
                            {
                                value: 'yes',
                                label: 'Yes',
                            },
                            {
                                value: 'no',
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
            ) : null}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default VisitCallFilter;
