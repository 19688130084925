import { CloseOutlined } from '@ant-design/icons';
import { Button, Pagination, Space, Table, message } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import {
    useApproveAllByToMutation,
    useApproveOrRejectPjpMutation,
    useGetPjpDraftDataMutation,
    useRejectAllByToMutation,
} from '../../redux/features/pjpManagement/pjpManagementApi';
import { resetPjpStatusFilter } from '../../redux/features/pjpManagement/pjpMappingFilter';
import TableSkeleton from '../../ui/TableSkeleton';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function ApprovePjp() {
    const dispatch = useDispatch();

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    const [pjpData, setPjpData] = useState([]);

    const { route, outletCode, channel, outletType } = useSelector(
        (state) => state.pjpMappingFilter
    );

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const [getPjpDraftData, { data, isLoading }] = useGetPjpDraftDataMutation();

    function getBodyData(r, oCode, c, oType) {
        const bodyData = {};
        if (r) {
            bodyData.route = r;
        }
        if (oCode) {
            bodyData.outletcode = oCode;
        }
        if (c) {
            bodyData.channel = c;
        }
        if (oType.length) {
            bodyData.outletType = oType;
        }
        return bodyData;
    }

    const fetPjpDraftData = async (pageNumber, totalPageChange, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        try {
            const result = await getPjpDraftData({
                ...getBodyData(route, outletCode, channel, outletType),
                ...getDataManagementFilterData({ circle, region, area, territory, town }),
                page: pageNumber,
                limit: totalPageChange,
            }).unwrap();
            // eslint-disable-next-line no-underscore-dangle
            setPjpData(result?.data?.map((x) => ({ id: x._id, ...x })));
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetPjpDraftData(pageNumber, totalPageChange);
    };

    const [approvedData, setApprovedData] = useState([]);

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setApprovedData(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.actionType === 'Approved',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    async function fetchData() {
        try {
            const result = await getPjpDraftData({
                page: currentPage,
                limit: totalShowPage,
            }).unwrap();
            // eslint-disable-next-line no-underscore-dangle
            setPjpData(result?.data?.map((x) => ({ id: x._id, ...x })));
        } catch (error) {
            message.error('Something went wrong');
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPjpDraftData]);

    const [approveOrRejectPjp, { isLoading: approveOrRejectLoading }] =
        useApproveOrRejectPjpMutation();

    const [approveAllByTo, { isLoading: allApproveLoading }] = useApproveAllByToMutation();
    const [rejectAllByTo, { isLoading: allRejectLoading }] = useRejectAllByToMutation();

    const submitApprove = () => {
        confirmAlert({
            title: `Confirm to Approve All`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const result = await approveAllByTo().unwrap();
                            message.success('Task Complete');
                            fetchData();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    const rejectPjpSubmit = () => {
        confirmAlert({
            title: `Confirm to Reject All`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const result = await rejectAllByTo().unwrap();
                            message.success('Task Complete');
                            fetchData();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    const singleApprove = (record) => {
        confirmAlert({
            title: `Confirm to Approved`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const result = await approveOrRejectPjp([
                                { id: record.id, status: 'Approved' },
                            ]).unwrap();
                            message.success('Task Complete');
                            fetchData();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    const singleReject = (record) => {
        confirmAlert({
            title: `Confirm to Reject`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const result = await approveOrRejectPjp([
                                { id: record.id, status: 'Rejected' },
                            ]).unwrap();
                            message.success('Task Complete');
                            fetchData();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    const { reFetchFilter, globalLoading } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetPjpStatusFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // draft file download
    // download button
    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    ...getBodyData(route, outletCode, channel, outletType),
                    ...getDataManagementFilterData({ circle, region, area, territory, town }),
                    // eslint-disable-next-line no-plusplus
                }),
                mode: 'cors',
            });

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Approve PJP" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    downloadButton={download}
                    loading={isLoading || globalLoading}
                    queryFunc={fetPjpDraftData}
                    pathname="/pjp-approve"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">PJP Mapping</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <Table
                            size="small"
                            rowKey="id"
                            loading={isLoading}
                            dataSource={pjpData?.map((x) => ({
                                ...x,
                                // eslint-disable-next-line no-underscore-dangle
                                id: x._id,
                                region: x?.town?.region,
                                area: x?.town?.area,
                                territory: x?.town?.territory,
                                town: x?.town?.name,
                                outletName: x?.outlet?.name,
                                outletType: x?.outlet?.outletType,
                                slab: x?.outlet?.slab,
                                channel: x?.outlet?.channel,
                                cmName: x?.assignTo?.name,
                                assignBy: x?.assignBy?.name,
                                approveRejectBy: x?.actionBy?.name,
                            }))}
                            scroll={{ x: 800, y: 500 }}
                            pagination={false}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                        >
                            <Column
                                title="Region"
                                dataIndex="region"
                                key="region"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                                // width="10%"
                            />
                            <Column
                                title="Area"
                                dataIndex="area"
                                key="area"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                                // width="10%"
                            />
                            <Column
                                title="Territory"
                                dataIndex="territory"
                                key="territory"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                                // width="10%"
                            />
                            <Column
                                title="Town"
                                dataIndex="town"
                                key="town"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                                // width="10%"
                            />
                            <Column
                                title="Outlet"
                                dataIndex="outletName"
                                key="outletName"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Channel"
                                dataIndex="channel"
                                key="channel"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Outlet Type"
                                dataIndex="outletType"
                                key="outletType"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Slab"
                                dataIndex="slab"
                                key="slab"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="CM Name"
                                dataIndex="cmName"
                                key="cmName"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Service Day"
                                dataIndex="serviceDay"
                                key="serviceDay"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Week No"
                                dataIndex="weekNo"
                                key="weekNo"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Status"
                                dataIndex="actionType"
                                key="actionType"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Assign By"
                                dataIndex="assignBy"
                                key="assignBy"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />
                            <Column
                                title="Approve/Reject By"
                                dataIndex="approveRejectBy"
                                key="approveRejectBy"
                                render={(val) => (
                                    <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                                        {val}
                                    </span>
                                )}
                            />

                            <Column
                                title="Action"
                                dataIndex="action"
                                key="action"
                                render={(_, record) => (
                                    <Space>
                                        {record.actionType === 'Approved' ? null : (
                                            <>
                                                {/* <Button
                                                    size="small"
                                                    type="primary"
                                                    shape="circle"
                                                    onClick={() => singleApprove(record)}
                                                    icon={<CheckOutlined />}
                                                /> */}
                                                <Button
                                                    size="small"
                                                    danger
                                                    type="primary"
                                                    shape="circle"
                                                    onClick={() => singleReject(record)}
                                                    icon={<CloseOutlined />}
                                                />
                                            </>
                                        )}
                                    </Space>
                                )}
                            />
                        </Table>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px 0',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.meta.count}
                        onChange={onChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>

                <div style={{ textAlign: 'center', padding: '0 0 20px 0' }}>
                    <Space>
                        <Button
                            loading={allApproveLoading}
                            disabled={allApproveLoading}
                            onClick={submitApprove}
                            type="primary"
                            size="large"
                        >
                            Approve All
                        </Button>
                        <Button
                            loading={allRejectLoading}
                            disabled={allRejectLoading}
                            onClick={rejectPjpSubmit}
                            danger
                            type="primary"
                            size="large"
                        >
                            Reject All
                        </Button>
                    </Space>
                </div>
            </div>
        </>
    );
}

export default ApprovePjp;
