/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
import {
    AimOutlined,
    ApartmentOutlined,
    AuditOutlined,
    BellOutlined,
    BgColorsOutlined,
    BlockOutlined,
    BranchesOutlined,
    BulbOutlined,
    CloudDownloadOutlined,
    CodeOutlined,
    ContainerOutlined,
    CrownOutlined,
    DeploymentUnitOutlined,
    FolderOpenOutlined,
    FundViewOutlined,
    NotificationOutlined,
    PicLeftOutlined,
    PieChartOutlined,
    SplitCellsOutlined,
    TagsOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';

export const items = [
    {
        label: 'Profile',
        key: '/profile',
        icon: <UserOutlined />,
        serial: 1,
    },
    {
        label: 'Dashboard',
        key: '/',
        icon: <PieChartOutlined />,
        serial: 2,
    },
    {
        label: 'Attendance',
        key: '/attendance',
        icon: <AimOutlined />,
        serial: 3,
    },
    {
        label: 'Tickets',
        key: '/tickets',
        icon: <TagsOutlined />,
        serial: 4,
    },
    {
        label: 'Daily Activity Report',
        key: '/daily-activity-report',
        icon: <FundViewOutlined />,
        serial: 5,
    },
    {
        label: 'Route Plan Tracker',
        key: '/route-plan-tracker',
        icon: <BranchesOutlined />,
        serial: 6,
    },
    {
        label: 'Login Details',
        key: '/login-details',
        icon: <CodeOutlined />,
        serial: 7,
    },
    {
        label: 'Team Management',
        key: '/team-management',
        icon: <TeamOutlined />,
        children: [
            {
                label: 'Add Employee',
                key: '/add-employee',
            },
            {
                label: 'View Employee',
                key: '/view-employee',
            },
        ],
        serial: 8,
    },
    {
        label: 'PJP Management',
        key: '/pjp-management',
        icon: <ApartmentOutlined />,
        children: [
            {
                label: 'PJP Status',
                key: '/pjp-status',
            },
            {
                label: 'PJP Draft Upload',
                key: '/pjp-draft-upload',
            },
            {
                label: 'PJP Mapping',
                key: '/pjp-mapping',
            },
            {
                label: 'Approve PJP',
                key: '/approve-pjp',
            },
            {
                label: 'Download Draft',
                key: '/download-draft',
            },
        ],
        serial: 9,
    },
    {
        label: 'Inventory',
        key: '/inventory',
        icon: <FolderOpenOutlined />,
        children: [
            {
                label: 'Town POSM History',
                key: '/inventory/-posm-history',
            },
            {
                label: 'Town POSM Summary',
                key: '/inventory/town-posm-summary',
            },
            {
                label: 'POSM Damage & Lost',
                key: '/inventory/posm-damage-lost',
            },
            {
                label: 'FF POSM History',
                key: '/inventory/ff-posm-history',
            },
            {
                label: 'FF POSM Summary',
                key: '/inventory/ff-posm-summary',
            },
        ],
        serial: 10,
    },
    {
        label: 'POSM Management',
        key: '/posm-management',
        icon: <BlockOutlined />,
        children: [
            {
                label: 'Add POSM',
                key: '/add-posm',
            },
            {
                label: 'View POSM List',
                key: '/view-posm-list',
            },
            {
                label: 'POSM Allocation',
                key: '/posm-allocation',
            },
            {
                label: 'Town POSM Movement',
                key: '/town-posm-movement',
            },
            {
                label: 'POSM Approve/Reject Transfer',
                key: '/posm-approve-reject-transfer',
            },
        ],
        serial: 11,
    },
    {
        label: 'Interim Report',
        key: '/interim-report',
        icon: <BgColorsOutlined />,
        children: [
            {
                label: 'Fixed Display Audit',
                key: '/interim-fixed-display-audit',
            },
            {
                label: 'QPDS',
                key: '/interim-report-qpds',
            },
        ],
        serial: 12,
    },
    {
        label: 'Notice',
        key: '/notice',
        icon: <BellOutlined />,
        serial: 13,
    },
    {
        label: 'Training Module',
        key: '/training-module',
        icon: <ContainerOutlined />,
        serial: 14,
    },
    {
        label: 'Leave Approval',
        key: '/leave-management',
        icon: <SplitCellsOutlined />,
        serial: 15,
    },
    
    
    {
        label: 'Super User',
        key: '/superuser',
        icon: <CrownOutlined />,
        children: [
            {
                label: 'Add Group',
                key: '/add-group',
            },
            {
                label: 'View Group',
                key: '/view-group',
            },
            {
                label: 'Add User',
                key: '/add-user',
            },
            {
                label: 'View User',
                key: '/view-user',
            },
            {
                label: 'Diff Checker',
                key: '/diff-checker',
            },
        ],
        serial: 16,
    },
    {
        label: 'Wholesale Report',
        key: '/wholesale-report',
        icon: <DeploymentUnitOutlined />,
        children: [
            {
                label: 'Wholesale Dashboard',
                key: '/wholesale-dashboard',
            },
            {
                label: 'National Level',
                key: '/national-level',
            },
            {
                label: 'Territory Level',
                key: '/territory-level',
            },
            {
                label: 'Wholesale Download Report',
                key: '/wholesale-download-report',
            },
        ],
        serial: 16,
    },

    
    
    {
        label: 'Visit Call Report',
        key: '/visit-call-report',
        icon: <NotificationOutlined />,
        serial: 17,
    },
    {
        label: 'CM SUP Call Report',
        key: '/cm-sup-call-report',
        icon: <AuditOutlined />,
        serial: 18,
    },
    {
        label: 'MTM Visit Call',
        key: '/mtm-visit-call',
        icon: <PicLeftOutlined />,
        serial: 19,
    },
    {
        label: 'Backlit Audit',
        key: '/backlit-audit',
        icon: <BulbOutlined />,
        serial: 20,
    },
    
    
    {
        label: 'Download Report',
        key: '/download-report',
        icon: <CloudDownloadOutlined />,
        serial: 20,
    },
];