/* eslint-disable no-underscore-dangle */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { useDeleteNoticeMutation } from '../../redux/features/notice/noticeApi';
import labelChange from '../../util/labelChange';

const { Column, ColumnGroup } = Table;

function NoticeTable({ loading, noticeData }) {
    // delete hook
    const [deleteNotice, { isLoading }] = useDeleteNoticeMutation();

    // accessToken
    const { accessToken } = useSelector((state) => state.auth);

    // delete notice
    const deleteNoticeFunction = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteNotice(id);
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    return (
        <Table
            rowKey="_id"
            scroll={{ x: 800 }}
            size="small"
            pagination={false}
            loading={loading || isLoading}
            dataSource={noticeData}
        >
            <Column
                title="Create Date"
                dataIndex="createdAt"
                key="createdAt"
                render={(_, record) => dayjs(record.createdAt).format('DD/MM/YYYY hh:mm A')}
            />
            <Column title="Description" dataIndex="description" key="description" />
            <Column
                title="Publish Date"
                dataIndex="publishAt"
                key="publishAt"
                render={(_, record) => dayjs(record.publishAt).format('DD/MM/YYYY')}
            />
            <Column
                title="Expire Date"
                dataIndex="expireAt"
                key="expireAt"
                render={(_, record) => dayjs(record.expireAt).format('DD/MM/YYYY')}
            />
            <Column
                title="Target FF Level"
                dataIndex="targetOf"
                key="targetOf"
                render={(_, record) => record.targetOf?.map((x) => labelChange(x)).join(', ')}
            />
            <Column
                title="Action"
                key="action"
                align="right"
                render={(_, record) => (
                    <Space size="middle">
                        <Button
                            size="small"
                            icon={<DeleteOutlined />}
                            type="primary"
                            danger
                            shape="circle"
                            onClick={() => deleteNoticeFunction(record._id)}
                        />
                    </Space>
                )}
            />
        </Table>
    );
}

export default NoticeTable;
