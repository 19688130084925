/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Pagination, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetBacklitDataMutation } from '../../redux/features/backlit/backlitApi';
import { resetQuarterFilter } from '../../redux/features/backlit/backlitFilterSlice';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import ExecutionSkeleton from '../../ui/ExecutionSkeleton';
import NoResult from '../../ui/NoResult';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import ExecutionItem from './ExecutionItem';

function BacklitAudit() {
    const { accessToken } = useSelector((state) => state.auth);

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const { quarter, status, outletCode } = useSelector((state) => state.backlitFilter);

    const getBodyData = (q, s, o) => {
        const bodyData = {};
        if (q) {
            bodyData.quarter = q.split('-').reverse().join('');
        }
        if (s) {
            bodyData.status = s;
        }
        if (o) {
            bodyData.outletCode = o;
        }
        return bodyData;
    };

    const [getBacklitData, { data, isLoading, isError, isSuccess, error }] =
        useGetBacklitDataMutation();

    const fetchExecutionData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        getBacklitData({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({
                region,
                area,
                territory,
                town,
            }),
            ...getBodyData(quarter, status, outletCode),
        });
    };

    useEffect(() => {
        getBacklitData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchExecutionData(pageNumber, totalPageChange);
    };

    const dispatch = useDispatch();
    const { reFetchFilter, globalLoading } = useSelector((state) => state.globalLoading);

    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetQuarterFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Backlit Audit', user: user.name });
    }, [user.name]);

    // download report function start
    // check json
    function checkJSON(doc) {
        return doc?.headers.get('Content-Type').includes('json');
    }

    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    // download link function
    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            for (let index = -1; ; ) {
                // eslint-disable-next-line no-await-in-loop
                const [result1, result2, result3] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: ` Bearer ${accessToken}`,
                        },
                        // eslint-disable-next-line no-plusplus
                        body: JSON.stringify({
                            ...getDataManagementFilterData({
                                circle,
                                region,
                                area,
                                territory,
                                town,
                            }),
                            ...getBodyData(quarter, status, outletCode),
                            index: ++index,
                        }),
                        mode: 'cors',
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: ` Bearer ${accessToken}`,
                        },
                        // eslint-disable-next-line no-plusplus
                        body: JSON.stringify({
                            ...getDataManagementFilterData({
                                circle,
                                region,
                                area,
                                territory,
                                town,
                            }),
                            ...getBodyData(quarter, status, outletCode),
                            index: ++index,
                        }),
                        mode: 'cors',
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: ` Bearer ${accessToken}`,
                        },
                        // eslint-disable-next-line no-plusplus
                        body: JSON.stringify({
                            ...getDataManagementFilterData({
                                circle,
                                region,
                                area,
                                territory,
                                town,
                            }),
                            ...getBodyData(quarter, status, outletCode),
                            index: ++index,
                        }),
                        mode: 'cors',
                    }),
                ]);
                const typeJSON1 = checkJSON(result1);
                const typeJSON2 = checkJSON(result2);
                const typeJSON3 = checkJSON(result3);

                const arr = [];

                if (!typeJSON1) arr.push(downLoadingFile(result1));
                if (!typeJSON2) arr.push(downLoadingFile(result2));
                if (!typeJSON3) arr.push(downLoadingFile(result3));

                // eslint-disable-next-line no-await-in-loop
                await Promise.all(arr);

                if (typeJSON1 || typeJSON2 || typeJSON3) break;
            }
        } catch (err) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
    // download report function end

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Backlit Audit" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    downloadButton={download}
                    loading={isLoading || globalLoading}
                    queryFunc={fetchExecutionData}
                    pathname="/backlitAudit"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Backlit Audit</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {!isLoading ? (
                        <>
                            {data?.data.length <= 0 || !data ? (
                                <NoResult />
                            ) : (
                                <>
                                    {data?.data.map((item, i) => (
                                        <ExecutionItem
                                            quarter={quarter}
                                            loading={isLoading}
                                            data={item}
                                            key={i}
                                            queryFunc={fetchExecutionData}
                                            page={currentPage}
                                            limit={totalShowPage}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {new Array(5).fill('').map((_, i) => (
                                <ExecutionSkeleton key={i} />
                            ))}
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            size="large"
                            pageSize={totalShowPage}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultCurrent={1}
                            total={data?.meta.count}
                            onChange={onChange}
                            showTotal={(total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default BacklitAudit;
