import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutComponent from './Layout/Layout';
import routeData from './data/routeData';
import useAuthCheck from './hook/useAuthCheck';
import Login from './pages/Login';
import PrivateRoute from './pages/PrivateRoute';
import { useWhoAmIQuery } from './redux/features/auth/authApi';
import NotFound from './ui/NotFound';
import UnAuthorized from './ui/UnAuthorized';

function App() {
    const authChecked = useAuthCheck();

    const { data, isLoading } = useWhoAmIQuery();

    return !authChecked ? (
        <div>
            <h2>Checking authentication....</h2>
        </div>
    ) : (
        <Routes>
            {routeData.map(({ path, element }, i) => (
                <Route
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    path={path}
                    element={
                        <PrivateRoute>
                            <LayoutComponent>{element}</LayoutComponent>
                        </PrivateRoute>
                    }
                />
            ))}

            <Route path="/un-authorized" element={<UnAuthorized />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
export default App;
